<template>
  <div>
    <van-dialog
      v-model="visible"
      title="作业升级审批"
      show-cancel-button
      :before-close="beforeClose"
    >
      <van-form ref="form" :show-error-message="false" label-width="150">
        <div class="desc">
          即将到下班时间，按照管理办法要求当前作业需要升级，请选择作业审批人进行审批：
        </div>
        <van-field
          :value="upgradeUserName"
          readonly
          is-link
          name="upgradeUser"
          label="升级后作业批准人"
          input-align="right"
          placeholder="请选择"
          :rules="[{ required: true, message: ' ' }]"
          @click="popupVisible = true"
        />
        <van-field
          :value="nightWorkNoApproveUserName"
          readonly
          name="reminder"
          label="若到下班时间该批准人未审批，提醒"
          input-align="right"
          placeholder=""
        />
      </van-form>
    </van-dialog>
    <select-popup
      :choose-value="formData.upgradeUser"
      :data="approverOptions"
      title="升级后作业批准人"
      :visible="popupVisible"
      show-filter
      :popup-style="{
        height: '50vh'
      }"
      value-key="id"
      label-key="name"
      @close="popupVisible = false"
      @confirm="upgradeUserChange"
    />
  </div>
</template>

<script>
import {
  getWorkUpgradeApproveUser,
  updateWorkTicketUpgrader
} from "@/api/workticket";

export default {
  name: "DialogUpgradation",
  inject: ["reload"],
  props: {},
  data() {
    return {
      visible: false,
      popupVisible: false,
      formData: {
        upgradeUser: ""
      },
      approverOptions: [],
      upgradeUserName: "",
      ticketId: "",
      nightWorkNoApproveUserName: ""
    };
  },
  methods: {
    open({ ticketId, department, fireLevel }) {
      this.getWorkUpgradeApproveUser(ticketId, department, fireLevel);
      this.ticketId = ticketId;
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    beforeClose(action, done) {
      if (action === "confirm") {
        this.confirm(done);
      } else {
        done();
      }
    },
    async confirm(done) {
      this.$refs.form
        .validate()
        .then(async () => {
          await updateWorkTicketUpgrader(this.ticketId, this.formData);
          this.$toast.success("保存成功");
          this.close();
          // 当前用户是批准人，则触发审批弹框
          if (this.formData.upgradeUser === this.userInfo.id) {
            this.reload();
          }
        })
        .catch(e => {
          done(false);
        });
    },
    upgradeUserChange(val) {
      this.formData.upgradeUser = val?.id ?? "";
      this.upgradeUserName = val?.name ?? "";
    },
    async getWorkUpgradeApproveUser(ticketId, department, fireLevel) {
      const params = { department, approveNodeName: "" };
      // 按产品要求，approveNodeName 固定传上一级审批节点名称，后端根据名称取审批人
      switch (Number(fireLevel)) {
        // 一级动火 传 特级动火审批节点名称
        case 2:
          params.approveNodeName = "经理或分管安全副经理或主任批准";
          break;
        // 二级动火 传 一级动火审批节点名称
        case 3:
          params.approveNodeName = "安全环保中心审批或批准";
          break;
      }
      const res = await getWorkUpgradeApproveUser(ticketId, params);
      this.approverOptions = res?.approveUser || [];
      this.nightWorkNoApproveUserName = res.nightWorkNoApproveUserName;
    }
  }
};
</script>

<style lang="scss" scoped>
.desc {
  font-size: 14px;
  line-height: 1.5;
  margin: 10px 10px 5px;
}
</style>
