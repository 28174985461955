<template>
  <van-popup
    v-model="visible"
    class="dialog-approve-all"
    :close-on-click-overlay="false"
    position="bottom"
    :overlay="false"
    get-container="body"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="header">
      <h3 class="title">确认卡</h3>
      <van-icon
        class="close-icon"
        name="mengcengguanbi"
        class-prefix="iconfont"
        @click="close"
      />
    </div>
    <section class="container">
      <van-form ref="form" class="form">
        <van-cell-group inset class="node-box">
          <van-field
            class="custom-input-person"
            :value="formData.deptCode"
            label="作业单位"
            v-bind="fieldProps"
            :clickable="true"
            :is-link="true"
            required
            :rules="[
              {
                required: true,
                message: '请选择作业单位',
                trigger: 'onChange'
              }
            ]"
            @click="deptClick"
          >
            <template #input>
              <p>
                <span class="person-box">{{ formData._deptName }}</span>
              </p>
            </template>
          </van-field>
          <van-field
            v-model="formData.content"
            label="作业内容"
            placeholder="请输入作业内容"
            maxlength="500"
            required
            clearable
            input-align="right"
            error-message-align="right"
            type="textarea"
            row="1"
            autosize
            :rules="[
              {
                required: true,
                message: '请填写作业内容',
                trigger: 'onChange'
              }
            ]"
          />
          <van-field
            class="custom-input-person"
            label="相关作业类别"
            v-bind="fieldProps"
          >
            <template #input>
              <p>
                <span class="person-box">{{ typeNames.toString() }}</span>
              </p>
            </template>
          </van-field>
        </van-cell-group>
      </van-form>
      <hr />
      <div class="title-bar">应急措施</div>
      <ul class="item-list">
        <li v-for="(item, index) of emergencyMeasures" :key="index">
          {{ index + 1 }}. {{ item.name }}
        </li>
      </ul>
      <hr />
      <div class="title-bar">安全培训</div>
      <div v-for="(item, index) of safetyTraining" :key="index">
        <p class="item-list-title">{{ item.workTypeName }}</p>
        <ul class="item-list">
          <li v-for="(itemC, i) of item.contentList" :key="i">
            {{ i + 1 }}. {{ itemC.name }}
          </li>
        </ul>
      </div>
    </section>
    <div class="footer">
      <van-button class="btn-default" @click="close">取消</van-button>
      <van-button class="btn-primary" @click="save">导出</van-button>
    </div>
    <select-popup
      v-model="formData.deptCode"
      title="作业单位"
      :visible="deptVisible"
      :data="deptOptions"
      :popup-style="{ height: '45vh' }"
      @close="deptVisible = false"
      @confirm="deptChange"
    />
  </van-popup>
</template>

<script>
import user from "@/utils/login";
import {
  getConfirmationCardDept,
  getEmergencyMeasures,
  getSafetyTraining,
  addConfirmationCard
} from "@/api/workticket";
export default {
  name: "ConfirmationCard",
  model: {
    prop: "value",
    event: "change"
  },
  data() {
    return {
      visible: false,
      deptVisible: false,
      formData: {
        ticketId: "",
        deptCode: "",
        _deptName: "",
        contractorStatus: "",
        content: "",
        workConfirmCardTypeList: []
      },
      fieldProps: {
        border: true,
        placeholder: "请输入",
        clearable: true,
        "input-align": "right",
        "error-message-align": "right"
      },
      deptOptions: [],
      emergencyMeasures: [],
      safetyTraining: [],
      typeIds: [],
      typeNames: []
    };
  },
  methods: {
    open(ticketId) {
      this.$refs.form && this.$refs.form.resetValidation();
      this.getDept(ticketId);
      this.getEmergencyMeasures();
      this.formData.ticketId = ticketId;
      this.visible = true;
    },
    close() {
      this.visible = false;
      this.formData = {
        ticketId: "",
        deptCode: "",
        _deptName: "",
        contractorStatus: "",
        content: "",
        workConfirmCardTypeList: []
      };
      this.typeIds = [];
      this.typeNames = [];
      this.safetyTraining = [];
    },
    async save() {
      this.$refs.form
        .validate()
        .then(async () => {
          const id = await addConfirmationCard(this.formData);
          this.close();
          this.exportFile(id);
        })
        .catch(error => {
          console.log("handSave--error", error);
        });
    },
    deptClick() {
      this.deptVisible = true;
    },
    async deptChange(val) {
      const find = this.deptOptions.find(
        item => item.deptCode === val.deptCode
      );
      if (find) {
        this.formData.deptCode = find.deptCode;
        this.formData._deptName = find.deptName;
        this.formData.contractorStatus = find.contractorStatus;
        this.formData.workConfirmCardTypeList = (find.workTypeList || []).map(
          item => {
            return { workTypeId: item.id };
          }
        );
        this.typeIds = (find.workTypeList || []).map(item => item.id);
        this.typeNames = (find.workTypeList || []).map(item => item.name);
        const safetyTraining = await getSafetyTraining({
          workTypeIdList: this.typeIds.toString()
        });
        this.safetyTraining = safetyTraining || [];
      } else {
        this.formData = {
          ...this.formData,
          deptCode: "",
          _deptName: "",
          contractorStatus: "",
          content: "",
          workConfirmCardTypeList: []
        };
        this.typeIds = [];
        this.typeNames = [];
        this.safetyTraining = [];
      }
    },
    async getDept(ticketId) {
      const data = await getConfirmationCardDept({ ticketId });
      this.deptOptions = (data || []).map(item => ({
        ...item,
        label: item.deptName,
        value: item.deptCode
      }));
    },
    async getEmergencyMeasures() {
      const data = await getEmergencyMeasures();
      this.emergencyMeasures = data || [];
    },
    exportFile(id) {
      const psmPath = window.globalConfig.VUE_APP_API_PSM_PATH;
      const token = user.getToken();
      const url = `${psmPath}/preview?id=${id}&module=work_ticket_confirm_card&token=${token}`;
      window.open(url, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-approve-all {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  hr {
    margin: 0;
  }
  .header {
    position: relative;
    text-align: center;
    color: #2e2e4d;
    padding: 30px 0 20px;
    .close-icon {
      position: absolute;
      top: 16px;
      left: 16px;
      color: #9496a3;
      font-size: 16px;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .container {
    height: calc(100vh - 100px - 80px);
    overflow: auto;
    overflow-x: hidden;
    padding-bottom: 50px;
    .node-box {
      &:not(:first-child) {
        border-top: 10px solid #eaebeb;
      }
      border-radius: 0;
    }
    .title-bar {
      font-size: 16px;
      font-weight: bold;
      padding: 15px;
    }
    .item-list-title {
      font-size: 14px;
      color: #3b4664;
      padding: 0 0 8px 24px;
    }
    .item-list {
      padding: 0 16px 16px 40px;
      li {
        font-size: 12px;
        color: #3b4664;
        line-height: 18px;
      }
    }
    ::v-deep .van-cell-group {
      margin: 0;
    }
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 16px;
    display: flex;
    width: 100%;
    z-index: 100;
    box-sizing: border-box;
    padding: 0px 16px;
    .van-button {
      flex-grow: 1;
      &:not(:last-child) {
        margin-right: 13px;
      }
    }
  }
}
</style>
