<template>
  <van-dialog
    v-model="visible"
    title="确认完成作业？"
    show-cancel-button
    :before-close="beforeClose"
  >
    <div class="person-signature-box">
      <div>
        <span class="label">交出人：</span>
        <van-button class="btn-select-person" round @click="choosePerson"
          >选择人员</van-button
        >
      </div>
      <div class="tag-box">
        <Tag
          v-for="item in selectedPerson"
          :key="item.personId"
          :type="item.fileId ? 'green' : 'white'"
          :tick="!!item.fileId"
          @click.native="handleClickTag(item)"
        >
          {{ item.personName }}
        </Tag>
      </div>
      <select-person
        title="交出人(单选)"
        :visible="personVisible"
        :choose-value="selectedPerson"
        :show-department-name="false"
        :show-phone-number="true"
        position="bottom"
        :include-children="true"
        :multiple="false"
        license-types=""
        department-disabled-key="--"
        :custom-list="personList"
        @close="personVisible = false"
        @confirm="personConfirm"
      />
    </div>
  </van-dialog>
</template>

<script>
import uniqBy from "lodash/uniqBy";
import Tag from "@/components/Tag";
import esign from "@/components/esign";
import { fileUrl } from "@/utils/constant";
import {
  getWorkSafeWork,
  addWorkSignature,
  finishWorking
} from "@/api/workticket";

export default {
  name: "DialogEquipmentSafetyAccomplish",
  inject: ["reload"],
  components: {
    Tag
  },
  data() {
    return {
      visible: false,
      personVisible: false,
      selectedPerson: [],
      personList: [],
      ticketId: ""
    };
  },
  methods: {
    open({ ticketId }) {
      this.ticketId = ticketId;
      this.getWorkSafeWork();
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    async getWorkSafeWork() {
      const res = await getWorkSafeWork(this.ticketId);
      let persons = [];
      res.forEach(item => {
        if (
          [1, 2, 3, 15].includes(Number(item.personType)) &&
          item.workerList?.length
        ) {
          persons.push(...(item.workerList[0]?.workerList || []));
        }
      });
      persons = uniqBy(persons, "personId");
      persons = persons.map(item => {
        item.fileId = "";
        return item;
      });
      this.personList = persons;
    },
    choosePerson() {
      this.personVisible = true;
    },
    handleClickTag(item) {
      if (item.fileId) {
        //已上传过，直接预览
        esign.showImage(fileUrl + item.fileId);
        return;
      }
      esign.show(imageRes => {
        item.fileId = imageRes.id;
        addWorkSignature({
          fileId: item.fileId,
          personId: item.personId,
          personType: "40",
          specialId: item.specialId,
          ticketId: this.ticketId
        })
          .then(res => {
            console.log(886, res, item.fileId);
            // item.fileId = fileId;
            this.$toast.success("提交成功");
          })
          .catch(error => {
            this.$toast.fail("签名失败，请重试");
          });
      });
    },
    personConfirm(ids, list) {
      this.selectedPerson = list;
      this.personVisible = false;
    },
    async beforeClose(action, done) {
      if (action == "cancel") {
        done();
        return;
      }
      if (action === "confirm") {
        if (!this.selectedPerson[0]?.fileId) {
          this.$toast.fail("请签名");
          done(false);
          return;
        }
        const persons = this.selectedPerson.map(i => {
          return {
            ...i,
            id: i.personId,
            type: i._mechanismType || i.type || "0",
            personType: "40",
            specialWorkId: i.specialId
          };
        });
        await finishWorking(this.ticketId, {
          workerList: persons
        });
        done();
        this.reload();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.label {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: #15171b;
  height: 32px;
  line-height: 32px;
  vertical-align: top;
}
.person-signature-box {
  padding: 16px 20px 20px;
  .btn-select-person {
    display: inline-block;
    width: 86px;
    height: 32px;
    line-height: 30px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    background-color: #1676ff;
    color: #ffffff;
    padding: 0;
    margin: 0 0 16px;
  }
  // .tag-box {
  //   margin: 16px 0 0;
  // }
}
</style>
