<template>
  <van-popup
    v-model="visible"
    class="custom-popup"
    :close-on-click-overlay="false"
    get-container="body"
    closeable
    round
    v-bind="$attrs"
    @opened="onOpen"
    @click-close-icon="onClose"
    v-on="$listeners"
  >
    <section class="header">
      <span class="title">{{ title }}</span>
      <span></span>
    </section>
    <section class="content">
      <!-- <div
        v-for="(f, k) of innerFiles"
        :key="f.id"
        class="upload-emit-box img-box"
      >
        <van-image
          width="100%"
          height="100%"
          lazy-load
          radius="4"
          :src="f.url"
          @click="
            imageClick({
              images: innerFiles.map(i => i.url),
              startPosition: k
            })
          "
        />
        <van-icon
          v-if="modeType === '0'"
          class="delete-icon"
          name="shanchu"
          class-prefix="iconfont"
          size="22px"
          color="#F75858"
          @click.native="deleteImg(f.id, k)"
        />
      </div> -->
      <!-- <div
        v-if="modeType === '0'"
        class="upload-emit-box"
        @click="cameraUpload"
      >
        <van-icon name="plus" size="24" />
        <p>上传照片</p>
      </div> -->
      <FileUploader
        v-model="innerFiles"
        class="file-uploader-box"
        :unable-upload="modeType !== '0'"
        :max-count="5"
      />
      <p class="upload-tips">照片最多支持5张</p>
    </section>
    <section class="footer">
      <template v-if="modeType === '0'">
        <div class="btn-box">
          <van-button type="default" class="dispersion-btn" @click="onClose"
            >取消
          </van-button>
          <van-button type="info" class="dispersion-btn" @click="onConfirm"
            >确定
          </van-button>
        </div>
      </template>
    </section>
  </van-popup>
</template>

<script>
// import axios from "@/utils/axios";
// import { ImagePreview } from "vant";
// import { saveSafetyRegulation, getSafetyRegulation } from "@/api/workticket";
import { deepClone } from "@/utils";
import FileUploader from "@/components/fileuploader/FileUploader";

export default {
  name: "CameraUpload",
  components: {
    FileUploader
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: "拍照留痕"
    },
    files: {
      type: Array,
      default: () => []
    },
    // 模式 0编辑、1查看
    modeType: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      innerFiles: []
    };
  },
  computed: {},
  watch: {
    visible: {
      handler(newVal) {
        if (newVal) {
        }
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    // imageClick() {
    //   ImagePreview(...arguments);
    // },
    // deleteImg(fileId, idx) {
    //   this.innerFiles.splice(idx, 1);
    // },
    onOpen() {
      this.innerFiles = deepClone(this.files);
      this.$emit("opened");
    },
    onClose() {
      this.$emit("close");
    },
    async onConfirm() {
      if (this.modeType === "0") {
        this.$emit("confirm", this.innerFiles);
      }
    },
    iconClose() {
      // this.$dialog
      //   .confirm({
      //     message: "未保存的更改，确定要退出吗？",
      //     className: "custom-confirm-dialog",
      //     confirmButtonText: "放弃",
      //     confirmButtonColor: "#F13030",
      //     cancelButtonText: "再看看",
      //     cancelButtonColor: "#6E6E7D"
      //   })
      //   .then(() => {
      //     this.$emit("close");
      //   });
      this.$emit("close");
    }
    // uploadFile({ file }) {
    //   const filePath = window.globalConfig.VUE_APP_FILE_URL + "/file/upload";
    //   const fd = new FormData();
    //   fd.append("files", file);
    //   return axios.post(filePath, fd);
    // },
    // base64toFile(base64, filename) {
    //   let arr = base64.split(","),
    //     mime = arr[0].match(/:(.*?);/)[1],
    //     bstr = atob(arr[1]),
    //     n = bstr.length,
    //     u8arr = new Uint8Array(n);
    //   while (n--) {
    //     u8arr[n] = bstr.charCodeAt(n);
    //   }
    //   return new File([u8arr], filename, { type: mime });
    // },
    // getFileUrl(id, downloadFlag = true) {
    //   return `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=${downloadFlag}&fileName=${id}`;
    // },
    // cameraUpload() {
    //   // NOTE: 由相机拍照上传，不会再经过after-read事件
    //   if (!window.android || !window.android.takePhoto) {
    //     this.$toast.fail("此版本不支持相机拍照功能");
    //     return;
    //   }
    //   if (this.innerFiles.length > 5) {
    //     return this.$toast("照片最多支持5张");
    //   }
    //   window.cameraCallback = async (base64, filename) => {
    //     const file = this.base64toFile(base64, filename || "安全交底拍照.jpg");
    //     const uploadResult = await this.uploadFile({ file });
    //     const fileList = uploadResult || [];
    //     this.innerFiles.push({
    //       ...fileList[0],
    //       url: this.getFileUrl(fileList[0].id, false)
    //     });
    //     window.cameraCallback = null;
    //   };
    //   // 0 只是拍照，1 是拍照或者相册
    //   window.androidH5.takePhoto("cameraCallback", "1");
    // }
  }
};
</script>

<style lang="scss">
.custom-confirm-dialog {
  .van-dialog__message {
    font-size: 16px;
    font-weight: 500;
    color: #242f3b;
    line-height: 22px;
  }
  .van-dialog__footer {
    border-top: 1px solid #eaecf1;
  }
  .van-dialog__cancel {
    border-right: 1px solid #eaecf1;
  }
}
</style>

<style lang="scss" scoped>
.custom-popup {
  height: 50vh;
  overflow: hidden;
  .header {
    // position: relative;
    display: flex;
    justify-content: center;
    padding: 14px 16px 13px;
    .title {
      font-size: 18px;
      font-weight: 400;
      color: #2e2e4d;
      line-height: 17px;
    }
  }
  .content {
    padding: 16px;
    height: 100%;
    overflow-y: auto;
    // .upload-emit-box:nth-child(n + 3) {
    //   margin: 0 0 15px 0;
    // }
    // .upload-emit-box {
    //   display: inline-block;
    //   vertical-align: top;
    //   text-align: center;
    //   background: #f6f7fa;
    //   border: 1px solid #e1e3e8;
    //   border-radius: 4px;
    //   padding: 34px 21px 22px;
    //   margin: 0 15px 15px 0;
    //   width: 104px;
    //   height: 104px;
    //   box-sizing: border-box;
    //   font-size: 12px;
    //   font-weight: 400;
    //   color: #b8bcc6;
    // }
    // .img-box {
    //   padding: 0;
    //   position: relative;
    //   // img {
    //   //   width: 100%;
    //   //   height: 100%;
    //   // }
    //   .delete-icon {
    //     position: absolute;
    //     top: -9px;
    //     right: -9px;
    //   }
    // }
    .upload-tips {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: #b8bcc6;
      line-height: 17px;
    }
    .file-uploader-box {
      ::v-deep {
        .van-uploader__wrapper {
          .van-uploader__preview,
          .van-uploader__input-wrapper {
            // display: inline-block;
            // vertical-align: top;
            text-align: center;
            background: #f6f7fa;
            border: 1px solid #e1e3e8;
            border-radius: 4px;
            // padding: 34px 21px 22px;
            margin: 0 15px 15px 0;
            width: 104px;
            height: 104px;
            box-sizing: border-box;
            font-size: 12px;
            font-weight: 400;
            color: #b8bcc6;
            overflow: hidden;
            .van-uploader__preview-image,
            .upload-btn {
              width: 100%;
              height: 100%;
            }
          }
          .van-uploader__preview:nth-child(2n + 3) {
            margin: 0 0 15px 0;
          }
          .van-uploader__input-wrapper {
            margin: 0 0 15px 0;
          }
        }
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    // height: 50px;
    width: 100vw;
    box-sizing: border-box;
    padding: 0 16px 16px;
    line-height: 50px;
    background-color: #fff;
    z-index: 2;
    .btn-box {
      display: flex;
      justify-content: space-around;
    }
    .dispersion-btn {
      // width: 120px;
      flex: 1;
    }
    .dispersion-btn:not(:last-child) {
      margin: 0 13px 0 0;
    }
    .van-button {
      height: 40px;
      line-height: 40px;
      border-radius: $--button-height;
    }
    .van-button--normal {
      padding: 0 24px;
    }
  }
}
</style>
