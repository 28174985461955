<template>
  <van-popup
    v-model="visible"
    class="dialog-approve-all"
    :close-on-click-overlay="false"
    position="bottom"
    :overlay="false"
    get-container="body"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="header">
      <h3 class="title">审批人会签-{{ title }}</h3>
      <van-icon
        class="close-icon"
        name="mengcengguanbi"
        class-prefix="iconfont"
        @click="close"
      />
    </div>
    <hr />
    <section class="container">
      <van-form ref="form" class="form">
        <van-cell-group
          v-for="(item, index) in formData.countersignLogDTO"
          :key="index"
          inset
          class="node-box"
        >
          <div class="title">{{ item.approveNodeName }}</div>
          <van-field
            class="custom-input-person"
            :value="item._approveVerdictName"
            label="审批结论"
            v-bind="fieldProps"
            :clickable="true"
            :is-link="true"
            required
            :rules="[
              {
                required: true,
                message: '请选择审批结论',
                trigger: 'onChange'
              }
            ]"
            @click="approveVerdictClick(index)"
          >
            <template #input>
              <p v-if="item.approveVerdict">
                <span class="person-box">{{ item._approveVerdictName }}</span>
              </p>
            </template>
          </van-field>
          <van-field
            v-model="item.approveOpinion"
            :label="item.approveVerdict === 1 ? '审批意见' : '退回原因'"
            :placeholder="
              `请输入${item.approveVerdict === 1 ? '审批意见' : '退回原因'}`
            "
            maxlength="500"
            :required="item.approveVerdict !== 1"
            clearable
            input-align="right"
            error-message-align="right"
            type="textarea"
            row="1"
            autosize
            :rules="[
              {
                required: item.approveVerdict !== 1,
                trigger: 'onChange'
              }
            ]"
          />
          <van-field
            class="custom-input-person"
            label="审批人"
            required
            v-bind="fieldProps"
          >
            <template #input>
              <div>
                <div
                  :v-if="item.approveDTOS.length"
                  :class="[
                    'tag-box',
                    item.approveDTOS.length === 1 && 'inline'
                  ]"
                >
                  <Tag
                    v-for="p in item.approveDTOS"
                    :key="p.approver"
                    class="tag"
                    :type="p.signature ? 'green' : 'white'"
                    :tick="!!p.signature"
                    @click.native="
                      handleClickTag(p.signature, index, p.approver)
                    "
                  >
                    {{ getPersonName(p.approver, index) }}
                  </Tag>
                </div>
                <van-button
                  class="btn-select-person"
                  round
                  @click="choosePerson(index)"
                  >选择人员</van-button
                >
              </div>
            </template>
          </van-field>
        </van-cell-group>
      </van-form>
    </section>
    <div class="footer">
      <van-button class="btn-primary" @click="save">提交</van-button>
    </div>
    <select-popup
      v-model="approveVerdict"
      title="审批结论"
      :visible="approveVerdictVisible"
      :data="approveVerdictOptions"
      :popup-style="{ height: '45vh' }"
      @close="approveVerdictVisible = false"
      @confirm="approveVerdictConfirm"
    />
    <select-person
      title="审批人(多选)"
      :visible="choosePersonVisible"
      :show-department-name="false"
      :show-phone-number="false"
      :show-department-search="false"
      :show-keyword-search="false"
      position="bottom"
      :include-children="true"
      :multiple="true"
      :is-department-auth="false"
      :choose-value="chooseValueList[activeIndex]"
      :custom-list="approverList[activeIndex]"
      @close="choosePersonVisible = false"
      @confirm="onPersonConfirm"
    />
  </van-popup>
</template>

<script>
import { getWorkNodes, countersignApprove } from "@/api/workticket";
import { fileUrl } from "@/utils/constant";
import esign from "@/components/esign";
import projectTextConfig from "@/utils/projectTextConfig";
import { WORKTICKET_TYPE } from "@/views/workticket/utils/constant";

export default {
  name: "DialogApproveAll",
  model: {
    prop: "value",
    event: "change"
  },
  inject: ["reload"],
  props: {
    data: Array
  },
  data() {
    return {
      visible: false,
      approveVerdictVisible: false,
      choosePersonVisible: false,
      title: "",
      formData: {
        ticketId: "",
        specialId: "",
        countersignLogDTO: []
      },
      approveVerdictOptions: [
        {
          label: "通过",
          value: 1
        },
        {
          label: "退回" + projectTextConfig.TEXT_SAFETY_ANALYSIS_DELIVER,
          value: 2
        },
        {
          label: "退回申请环节",
          value: 3
        }
      ],
      approveVerdict: "",
      fieldProps: {
        border: true,
        placeholder: "请输入",
        clearable: true,
        "input-align": "right",
        "error-message-align": "right"
      },
      activeIndex: 0,
      approverList: [[]],
      chooseValueList: [[]]
    };
  },
  methods: {
    async open(title = "", specialId, isEquipmentSafety) {
      const workNodes = await getWorkNodes(specialId);
      this.title = title;
      this.formData = {
        ticketId: this.$route.params.id,
        specialId,
        countersignLogDTO: workNodes.map(node => ({
          ticketId: node.ticketId,
          approveNodeId: node.id,
          approveNodeName: node.name,
          specialId,
          approveVerdict: this.approveVerdictOptions[0].value,
          _approveVerdictName: this.approveVerdictOptions[0].label,
          approveOpinion: "",
          approveDTOS: []
        }))
      };
      this.approverList = workNodes.map(item => item.approvers);
      this.chooseValueList = [];
      if (isEquipmentSafety) {
        this.approveVerdictOptions[1].label = "退回待检修安全交底";
        this.approveVerdictOptions[1].value = 4;
      } else {
        this.approveVerdictOptions[1].label = `退回${projectTextConfig.TEXT_SAFETY_ANALYSIS_DELIVER}`;
        this.approveVerdictOptions[1].value = 2;
      }
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    async save() {
      this.$refs.form
        .validate()
        .then(() => {
          let signStatus = [];
          let hasBack = false;
          let hasSign = false;
          this.formData.countersignLogDTO.forEach(item => {
            const isBack = [2, 3, 4].includes(item.approveVerdict);
            const signed = item.approveDTOS.some(item => item.signature);
            if (isBack && !hasBack) {
              hasBack = true;
            }
            if (isBack && signed && !hasSign) {
              hasSign = true;
            }
            if (item.approveDTOS.length) {
              const signed = item.approveDTOS.every(dto => dto.signature);
              signStatus.push(signed);
            } else {
              signStatus.push(false);
            }
          });

          if (hasBack) {
            // 退回
            if (!hasSign) {
              this.$toast.fail("退回节点未签名");
              return;
            }
          } else {
            // 通过
            let signCheck = signStatus.length && signStatus.every(flag => flag);
            if (!signCheck) {
              this.$toast.fail("签名未完成");
              return;
            }
          }
          countersignApprove(this.formData).then(() => {
            this.reload();
          });
        })
        .catch(error => {
          console.log("handSave--error", error);
        });
    },
    getWorkSiteTypeName(workSiteType) {
      if (!workSiteType) return "";
      const find = this.workSiteTypes.find(item => item.value === workSiteType);
      return find ? find.label : "";
    },
    approveVerdictClick(index) {
      this.approveVerdict = this.formData.countersignLogDTO[
        index
      ].approveVerdict;
      this.approveVerdictVisible = true;
      this.activeIndex = index;
    },
    approveVerdictConfirm(item) {
      const newData = {
        ...this.formData.countersignLogDTO[this.activeIndex],
        approveVerdict: item.value,
        _approveVerdictName: item.label
      };
      this.$set(this.formData.countersignLogDTO, this.activeIndex, newData);
    },
    onPersonConfirm(ids, list) {
      const newData = ids.map(id => {
        // 保留签名状态
        const find = this.formData.countersignLogDTO[
          this.activeIndex
        ].approveDTOS.find(item => item.approver === id);
        return find || { approver: id, signature: "" };
      });
      this.chooseValueList[this.activeIndex] = list;
      this.$set(
        this.formData.countersignLogDTO[this.activeIndex],
        "approveDTOS",
        newData
      );
      const newApprover = this.approverList[this.activeIndex].map(item => ({
        ...item,
        checked: ids.includes(item.id)
      }));
      this.$set(this.approverList, this.activeIndex, newApprover);
    },
    choosePerson(index) {
      this.activeIndex = index;
      this.choosePersonVisible = true;
    },
    handleClickTag(fileId, index, id) {
      if (fileId) {
        //已上传过，直接预览
        esign.showImage(fileUrl + fileId);
        return;
      }
      esign.show(imageRes => {
        const newData = this.formData.countersignLogDTO.map(item => {
          return {
            ...item,
            approveDTOS: item.approveDTOS.map(ap => {
              if (ap.approver === id) {
                return {
                  ...ap,
                  signature: imageRes.id
                };
              }
              return ap;
            })
          };
        });
        this.$set(this.formData, "countersignLogDTO", newData);
      });
    },
    getPersonName(id, index) {
      const find = this.approverList[index].find(item => item.id === id);
      return find?.name || "";
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-approve-all {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  hr {
    margin: 0;
  }
  .header {
    position: relative;
    text-align: center;
    color: #2e2e4d;
    padding: 30px 0 20px;
    .close-icon {
      position: absolute;
      top: 16px;
      left: 16px;
      color: #9496a3;
      font-size: 16px;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .container {
    height: calc(100vh - 100px - 80px);
    overflow: auto;
    overflow-x: hidden;
    padding-bottom: 50px;
    .node-box {
      &:not(:first-child) {
        border-top: 10px solid #eaebeb;
      }
      border-radius: 0;
      .title {
        font-size: 16px;
        font-weight: bold;
        padding: 15px 0 15px 15px;
      }
    }
    ::v-deep .van-cell-group {
      margin: 0;
    }
  }
  .footer {
    position: fixed;
    left: 0;
    bottom: 16px;
    display: flex;
    width: 100%;
    z-index: 100;
    box-sizing: border-box;
    padding: 0px 16px;
    .van-button {
      flex-grow: 1;
      &:not(:last-child) {
        margin-right: 13px;
      }
    }
  }
  .btn-select-person {
    display: inline-block;
    width: 86px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    background-color: #1676ff;
    color: #ffffff;
    padding: 0;
    margin: 0 0 0 8px;
  }
  .tag-box {
    &.inline {
      display: inline-block;
    }
    .tag {
      margin: 0 0 8px 8px;
    }
  }
}
</style>
