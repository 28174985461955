var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('van-popup',_vm._g(_vm._b({staticClass:"dialog-approve-all",attrs:{"close-on-click-overlay":false,"position":"bottom","overlay":false,"get-container":"body"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},'van-popup',_vm.$attrs,false),_vm.$listeners),[_c('div',{staticClass:"header"},[_c('h3',{staticClass:"title"},[_vm._v("审批人会签-"+_vm._s(_vm.title))]),_c('van-icon',{staticClass:"close-icon",attrs:{"name":"mengcengguanbi","class-prefix":"iconfont"},on:{"click":_vm.close}})],1),_c('hr'),_c('section',{staticClass:"container"},[_c('van-form',{ref:"form",staticClass:"form"},_vm._l((_vm.formData.countersignLogDTO),function(item,index){return _c('van-cell-group',{key:index,staticClass:"node-box",attrs:{"inset":""}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.approveNodeName))]),_c('van-field',_vm._b({staticClass:"custom-input-person",attrs:{"value":item._approveVerdictName,"label":"审批结论","clickable":true,"is-link":true,"required":"","rules":[
            {
              required: true,
              message: '请选择审批结论',
              trigger: 'onChange'
            }
          ]},on:{"click":function($event){return _vm.approveVerdictClick(index)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [(item.approveVerdict)?_c('p',[_c('span',{staticClass:"person-box"},[_vm._v(_vm._s(item._approveVerdictName))])]):_vm._e()]},proxy:true}],null,true)},'van-field',_vm.fieldProps,false)),_c('van-field',{attrs:{"label":item.approveVerdict === 1 ? '审批意见' : '退回原因',"placeholder":("请输入" + (item.approveVerdict === 1 ? '审批意见' : '退回原因')),"maxlength":"500","required":item.approveVerdict !== 1,"clearable":"","input-align":"right","error-message-align":"right","type":"textarea","row":"1","autosize":"","rules":[
            {
              required: item.approveVerdict !== 1,
              trigger: 'onChange'
            }
          ]},model:{value:(item.approveOpinion),callback:function ($$v) {_vm.$set(item, "approveOpinion", $$v)},expression:"item.approveOpinion"}}),_c('van-field',_vm._b({staticClass:"custom-input-person",attrs:{"label":"审批人","required":""},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',[_c('div',{class:[
                  'tag-box',
                  item.approveDTOS.length === 1 && 'inline'
                ],attrs:{"v-if":item.approveDTOS.length}},_vm._l((item.approveDTOS),function(p){return _c('Tag',{key:p.approver,staticClass:"tag",attrs:{"type":p.signature ? 'green' : 'white',"tick":!!p.signature},nativeOn:{"click":function($event){return _vm.handleClickTag(p.signature, index, p.approver)}}},[_vm._v(" "+_vm._s(_vm.getPersonName(p.approver, index))+" ")])}),1),_c('van-button',{staticClass:"btn-select-person",attrs:{"round":""},on:{"click":function($event){return _vm.choosePerson(index)}}},[_vm._v("选择人员")])],1)]},proxy:true}],null,true)},'van-field',_vm.fieldProps,false))],1)}),1)],1),_c('div',{staticClass:"footer"},[_c('van-button',{staticClass:"btn-primary",on:{"click":_vm.save}},[_vm._v("提交")])],1),_c('select-popup',{attrs:{"title":"审批结论","visible":_vm.approveVerdictVisible,"data":_vm.approveVerdictOptions,"popup-style":{ height: '45vh' }},on:{"close":function($event){_vm.approveVerdictVisible = false},"confirm":_vm.approveVerdictConfirm},model:{value:(_vm.approveVerdict),callback:function ($$v) {_vm.approveVerdict=$$v},expression:"approveVerdict"}}),_c('select-person',{attrs:{"title":"审批人(多选)","visible":_vm.choosePersonVisible,"show-department-name":false,"show-phone-number":false,"show-department-search":false,"show-keyword-search":false,"position":"bottom","include-children":true,"multiple":true,"is-department-auth":false,"choose-value":_vm.chooseValueList[_vm.activeIndex],"custom-list":_vm.approverList[_vm.activeIndex]},on:{"close":function($event){_vm.choosePersonVisible = false},"confirm":_vm.onPersonConfirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }