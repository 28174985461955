<template>
  <van-dialog
    v-model="visible"
    title="作业升级审批"
    show-cancel-button
    @confirm="confirm"
  >
    <van-form ref="van-form" :show-error-message="false">
      <van-field
        required
        :rules="[{ required: true }]"
        label="审批意见"
        name="opinion"
      >
        <template #input>
          <van-radio-group v-model="formData.opinion" direction="horizontal">
            <van-radio
              v-for="item in radios"
              :key="item.value"
              :name="item.value"
              >{{ item.label }}</van-radio
            >
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="formData.remarks"
        rows="2"
        autosize
        label="说明"
        type="textarea"
        maxlength="500"
        placeholder="请输入内容"
        show-word-limit
      />
    </van-form>
  </van-dialog>
</template>

<script>
import { updateWorkUpgradeApprove } from "@/api/workticket";

export default {
  name: "DialogApprove",
  inject: ["reload"],
  props: {
    data: Array
  },
  data() {
    return {
      visible: false,
      formData: {
        opinion: 1,
        remarks: ""
      },
      radios: [
        {
          label: "同意",
          value: 1
        },
        {
          label: "不同意",
          value: 0
        }
      ],
      ticketId: ""
    };
  },
  methods: {
    open({ ticketId }) {
      this.ticketId = ticketId;
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    async confirm() {
      await updateWorkUpgradeApprove(this.ticketId, this.formData);
      if (this.formData.opinion === 0) {
        this.reload();
      }
      this.close();
    }
  }
};
</script>

<style lang="scss" scoped></style>
