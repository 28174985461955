<template>
  <van-dialog
    v-bind="$attrs"
    class-name="live-stream-dialog"
    @close="onClose"
    @open="onOpen"
    v-on="$listeners"
  >
    <div slot="title" class="title">
      <span>{{ videoName }}</span>
      <van-icon
        class="close-icon"
        name="mengcengguanbi"
        class-prefix="iconfont"
        @click.native="$emit('close')"
      />
    </div>
    <ty-live-player
      v-if="loadVideo"
      request-mode="preview"
      :camera-i-p="info.cameraIp"
      :org-code="info.orgCode"
      :nvr-addr="info.nvrAddr"
      :channel-id="info.channelAddr"
    />
  </van-dialog>
</template>

<script>
import { getInfoCameraById, getInfoMobileCameraById } from "@/api/workticket";
export default {
  name: "LiveStreamingDialog",
  components: {
    TyLivePlayer: () => import("@ty-live-player")
  },
  props: {
    videoId: String,
    // 摄像头类型0：摄像头1：移动摄像头
    videoType: {
      type: [String, Number],
      default: "0"
    },
    videoName: String
  },
  data() {
    return {
      loadVideo: false,
      info: {}
    };
  },
  methods: {
    onClose() {
      this.info = {};
      this.loadVideo = false;
    },
    onOpen() {
      this.getInfo();
    },
    async getInfo() {
      try {
        if (+this.videoType === 0) {
          this.info = await getInfoCameraById(this.videoId);
        } else {
          this.info = await getInfoMobileCameraById(this.videoId);
        }
        this.loadVideo = true;
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style lang="scss">
.live-stream-dialog {
  padding: 10px;
  border-radius: 0;
  .van-dialog__header {
    padding-top: 0;
  }
  .van-dialog__footer {
    display: none;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    font-weight: bold;
    span {
      color: #2e2e4d;
      font-size: 16px;
    }
    .close-icon {
      font-size: 12px;
      color: #b9bac5;
    }
  }
}
</style>
