<template>
  <van-popup
    v-model="visible"
    :close-on-click-overlay="false"
    :overlay="false"
    get-container="body"
    v-bind="$attrs"
    class="select-person playback-popup"
    @opened="onOpen"
    @close="onClose"
    @click-overlay="onClose"
    v-on="$listeners"
  >
    <section class="select-person__container">
      <div class="select-person__header">
        <h3 class="select-person__title">{{ videoName }}</h3>
        <van-icon
          class="close-icon"
          name="mengcengguanbi"
          class-prefix="iconfont"
          @click.native="$emit('close')"
        />
      </div>
      <div class="select-person__search">
        <date-time
          v-model="dateTime"
          :time-close="false"
          type="datetime"
          format="{y}-{m}-{d} {h}:{i}"
        />
      </div>
      <div class="video-wrapper">
        <div class="media">
          <video
            id="media"
            :style="{ visibility: currentUrl ? 'visible' : 'hidden' }"
            :src="currentUrl"
            object-fit
            controls
            muted
          />
        </div>
        <div class="line-block" />
        <div v-if="videoList.length" class="video-list-wrapper">
          <div
            v-for="item in videoList"
            :key="item.param"
            :class="['item', 'text-overflow', item.checked && 'checked']"
            @click="onPlayer(item)"
          >
            <span>{{ item.fileName }} </span>（<b>{{ item.durationFmt }}</b
            >）
          </div>
        </div>
        <box-empty v-else class="empty" />
      </div>
    </section>
  </van-popup>
</template>

<script>
import { getInfoCameraById, getInfoMobileCameraById } from "@/api/workticket";
import dayjs from "dayjs";
import axios from "@/utils/axios";
import { Toast } from "vant";
import BoxEmpty from "@/components/BoxEmpty";
const videoPath = window.globalConfig.VUE_APP_VIDEO_API_PATH;

export default {
  name: "PlaybackPopup",
  components: { BoxEmpty },
  props: {
    visible: Boolean,
    realStart: { type: String },
    realEnd: { type: String },
    videoId: String,
    // 摄像头类型0：摄像头1：移动摄像头
    videoType: {
      type: [String, Number],
      default: "0"
    },
    videoName: String
  },
  data() {
    return {
      mediaDom: null,
      currentUrl: "",
      dateTime: { start: "", end: "" },
      queryInfo: {},
      videoList: [],
      closeBefore: false
    };
  },
  watch: {
    dateTime: {
      handler() {
        !this.closeBefore && this.getVideoList();
      },
      deep: true
    }
  },
  methods: {
    onClose() {
      this.info = {};
      this.getMediaDom().pause();
      this.currentUrl = "";
      this.mediaDom = null;
      this.closeBefore = true;
      this.dateTime.start = "";
      this.dateTime.end = "";
    },
    async onOpen() {
      this.closeBefore = false;
      await this.getInfo();
      const { realEnd, realStart } = this;
      if (realEnd && realStart) {
        const format = x => dayjs(x).format("YYYY-MM-DD HH:mm");
        const dt = { start: format(realStart) };
        const day = dayjs(realEnd).diff(dayjs(realStart), "day");
        let end;
        if (day > 7) {
          end = dayjs(realStart).add(7, "day");
        } else {
          end = realEnd;
        }
        dt.end = format(end);
        this.dateTime = dt;
      }
      await this.$nextTick();
      this.getMediaDom().load();
    },
    async getInfo() {
      try {
        if (+this.videoType === 0) {
          this.info = await getInfoCameraById(this.videoId);
        } else {
          this.info = await getInfoMobileCameraById(this.videoId);
        }
      } catch (e) {
        console.log(e);
      }
    },
    getVideoList(deviceId, channelId) {
      deviceId = deviceId || this.info.nvrAddr;
      channelId = channelId || this.info.channelAddr;
      const startTime = this.dateTime.start ?? "";
      const endTime = this.dateTime.end ?? "";
      if (!startTime || !endTime) {
        return Toast("请选择时间，且不能超过7天");
      }
      const day = dayjs(endTime).diff(dayjs(startTime), "day");
      if (day > 7) {
        return Toast("时间不能超过7天");
      }
      const url = `${videoPath}/camera/v2/preview/cloud`;
      const format = x => dayjs(x).valueOf();
      const data = {
        deviceId,
        channelId,
        startTime: format(startTime),
        endTime: format(endTime)
      };
      axios.post(url, data).then(res => {
        this.videoList = (res ?? []).map(x => ((x.checked = false), x));
        this.onPlayer(this.videoList[0]);
      });
    },
    getMediaDom() {
      if (!this.mediaDom) {
        this.mediaDom = document.getElementById("media");
      }
      return this.mediaDom;
    },
    async getPlayerUrl(param, fullFileName) {
      const url = `${videoPath}/camera/v2/preview/downloadMp4/?${param}&fullFileName=${fullFileName}`;
      const res = await axios.get(url);
      return res ?? "";
    },
    resetChecked() {
      this.videoList.forEach(item => (item.checked = false));
    },
    async onPlayer(row) {
      if (!row) return;
      this.currentUrl = await this.getPlayerUrl(row.param, row.fileFullName);
      this.resetChecked();
      row.checked = true;
      // await this.$nextTick();
      // this.getMediaDom()
      //   .play()
      //   .then(console.log)
      //   .catch(e => {
      //     console.log(e);
      //     Toast.fail("播放失败，请重试");
      //   });
    }
  }
};
</script>

<style lang="scss">
.playback-popup {
  .select-person__container {
    margin: 0;
    .select-person__header {
      margin: 0 16px;
    }
  }
  .media {
    background-color: #000;
  }
  #media {
    width: 100%;
    height: 212px;
    object-fit: fill;
  }
  .empty {
    height: calc(100vh - 68px - 38px - 12px - 214px - 8px);
  }
  .video-list-wrapper {
    height: calc(100vh - 68px - 38px - 12px - 214px - 8px);
    overflow: auto;
    padding: 10px 0;
    .item {
      font-size: 14px;
      font-weight: 400;
      color: #2e2e4d;
      line-height: 38px;
      margin: 0 16px;
      b {
        color: rgba(#2e2e4d, 0.48);
      }
    }
    .checked {
      color: #1676ff;
      b {
        color: #1676ff;
      }
    }
  }
}
</style>
