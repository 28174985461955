<template>
  <div
    class="has-nav-bar view-workticket-detail"
    :class="{ 'safe-disclosure-sign': showSafeDisclosureSign }"
  >
    <WorkticketHeader
      :title="title"
      :show-process="true"
      :supervised-check="baseInfo.checkEdit"
      :show-user-change="
        !isWorkInspect &&
          ![
            WORKTICKET_STATUS.Safety_disclosure,
            WORKTICKET_STATUS.Safety_disclosureing
          ].includes(status)
      "
      :ticket-status.sync="status"
      :origin-status="baseInfo.status"
    >
    </WorkticketHeader>
    <div v-if="baseInfo">
      <div
        v-if="status === WORKTICKET_STATUS.Safety_disclosure_sign"
        class="top-box block-space_8_notLast"
      >
        <h1 class="title">安全措施确认</h1>
      </div>
      <div
        v-else-if="![WORKTICKET_STATUS.ReferralApproving].includes(status)"
        class="top-box block-space_8_notLast top-box-info"
      >
        <template v-if="baseInfo.name">
          <h1 class="title">
            {{ `${baseInfo.name}（${workTypeNames}）` }}
          </h1>
          <p class="desc">{{ baseInfo.code }}</p>
        </template>
        <div v-if="baseInfo.displayTerminalTime" class="countdown">
          <p class="label">
            <van-count-down :time="baseInfo.terminalTime" format="HH:mm:ss">
              <template #default="timeData">
                <span class="time-text">倒计时</span>
                <span
                  class="block"
                  :class="{ 'time-up': timeUpSwitch(timeData) }"
                >
                  {{ numberSwitch(timeData.days * 24 + timeData.hours) }}
                </span>
                <span class="colon">:</span>
                <span
                  class="block"
                  :class="{ 'time-up': timeUpSwitch(timeData) }"
                >
                  {{ numberSwitch(timeData.minutes) }}
                </span>
                <span class="colon">:</span>
                <span
                  class="block"
                  :class="{ 'time-up': timeUpSwitch(timeData) }"
                >
                  {{ numberSwitch(timeData.seconds) }}
                </span>
              </template>
            </van-count-down>
            <span
              v-if="$route.query.showWarningButton === 'true'"
              class="warning-button"
              @click="gotoDetail($route.params.id)"
              >报警记录</span
            >
          </p>
        </div>
      </div>
      <van-collapse v-model="activeName" class="van-collapse__cell">
        <van-collapse-item
          v-if="showBaseInfo"
          class="block-space_8_notLast"
          title="申请信息"
          name="申请信息"
          :border="false"
          :is-link="false"
        >
          <template #value>
            <van-button
              v-if="
                btnAuth.confirmationCard &&
                  [7, 8, 9, 10, 11, 12, 13].includes(baseInfo.status)
              "
              class="safe-btn"
              type="info"
              round
              @click.stop="$refs.confirmationCard.open(baseInfo.id)"
              >确认卡</van-button
            >
          </template>
          <van-cell title="关联作业活动" :value="baseInfo.workJsaName">
            <template #default>
              <span style="color: #1676ff" @click="workJsaRouterJump">{{
                baseInfo.workJsaName
              }}</span>
            </template>
          </van-cell>
          <van-cell
            :title="projectTextConfig.TEXT_JSA_NAME"
            :value="baseInfo.name"
          />
          <van-cell
            :title="projectTextConfig.TEXT_FACILITY_NAME"
            :value="baseInfo.facilityName"
          />
          <van-cell title="电子围栏" :value="baseInfo.riskAreaName" />
          <van-cell
            :title="`具体${TEXT_WORK_LOCATION}`"
            :value="baseInfo.location"
          />
          <!--          <van-cell title="设备" :value="baseInfo.facilitiesName" />-->
          <van-cell title="所属部门" :value="baseInfo.departmentNames" />
          <van-cell
            :title="TEXT_WORK_APPROVER"
            :value="
              (baseInfo.approverList || []).map(item => item.name).toString()
            "
          />
          <van-cell
            :title="TEXT_SAFETY_ANALYSIS_HEAD"
            :value="
              (baseInfo.supervisorList || []).map(item => item.name).toString()
            "
          />
          <van-cell title="计划开始时间" :value="baseInfo.planStart" />
          <van-cell title="计划结束时间" :value="baseInfo.planEnd" />
          <MaintenanceHours
            v-model="baseInfo.maintenanceHours"
            :start-time="baseInfo.planStart"
            :end-time="baseInfo.planEnd"
            :editable="false"
          />
          <van-cell
            v-if="baseInfo.workTypeList && baseInfo.workTypeList.length > 0"
            title="作业类别"
          >
            <template #label>
              <!-- <Tag
                v-for="(item, index) in baseInfo.workTypeList"
                :key="index"
                type="gray"
                :border="false"
                >{{ item.name }}
              </Tag> -->
              <div
                v-for="(item, idx) of baseInfo.workTypeList"
                :key="idx"
                class="work-type-box"
              >
                <div class="left-box">
                  <p class="type-name">{{ item.name }}</p>
                  <p>
                    {{ projectTextConfig.TEXT_WORK_DEPT }}：{{ item.deptName }}
                  </p>
                  <p>
                    所需资质：{{
                      item.licenseTypeName
                        ? item.licenseTypeName.toString()
                        : ""
                    }}
                  </p>
                  <p v-if="isBy">
                    人员：
                    {{
                      (item.workerList || [])
                        .filter(item => item.personType === "28")
                        .map(item => item.name)
                        .toString()
                    }}
                  </p>
                </div>
              </div>
            </template>
          </van-cell>
          <ProjectCategory
            :value.sync="baseInfo.projectType"
            :editable="false"
          />
          <ProjectRiskLevel
            :value.sync="baseInfo.riskLevel"
            :editable="false"
          />
          <ProjectLeader
            :value.sync="baseInfo.jsaProjectLeaderList"
            :phone-number.sync="baseInfo.jsaProjectLeaderPhone"
            :default-department="baseInfo.department"
            :editable="false"
          />
          <van-field
            v-if="isBy"
            :is-link="false"
            :clickable="false"
            :readonly="true"
            :required="false"
            :value="baseInfo.jsaProjectLeaderPhone"
            name="jsaProjectLeaderPhone"
            label="联系方式"
            placeholder="--"
            input-align="right"
            error-message-align="right"
          />
          <SafetyLeader
            :value.sync="baseInfo.jsaSafetyLeaderList"
            :default-department="baseInfo.department"
            :editable="false"
          />
          <OverhaulLeader
            :value.sync="baseInfo.jsaOverhaulLeaderList"
            :default-department="baseInfo.department"
            :editable="false"
          />
          <van-field
            v-if="isBy"
            :is-link="false"
            :clickable="false"
            :readonly="true"
            :required="false"
            :value="Number(baseInfo.changeFlag) === 1 ? '是' : '否'"
            name="jsaProjectLeaderPhone"
            label="是否涉及变更"
            placeholder="--"
            input-align="right"
            error-message-align="right"
          />
          <ProjectSource
            :value.sync="baseInfo.projectSource"
            :editable="false"
          />
          <van-field
            v-if="isBy"
            :value="baseInfo.remarks"
            label="备注"
            type="textarea"
            maxlength="1000"
            placeholder=""
            input-align="right"
            error-message-align="right"
            autosize
            readonly
          />
          <van-cell v-if="!isWorkInspect" title="关联作业票">
            <template #label>
              <ul class="work-link-box">
                <li
                  v-for="item of baseInfo.relationList"
                  :key="item.id"
                  class="item-box"
                >
                  <div class="left-box">
                    <van-icon
                      class="iconfont-lianjie"
                      name="lianjie"
                      class-prefix="iconfont"
                      size="16"
                    />
                  </div>
                  <div class="content">
                    <span @click="workTicketRouterLink(item.id)">
                      {{
                        `【${item.statusName}】${
                          item.name
                        }（${item.specialList.toString()}）${item.code}`
                      }}
                    </span>
                  </div>
                </li>
              </ul>
            </template>
          </van-cell>
          <van-cell
            title="申请人"
            :value="
              (baseInfo.proposerList || []).map(item => item.name).toString()
            "
          />
          <van-cell title="申请时间" :value="baseInfo.ctime" />
          <template v-if="baseInfo.allowStart && baseInfo.allowEnd">
            <van-cell title="许可作业开始时间" :value="baseInfo.allowStart" />
            <van-cell title="许可作业结束时间" :value="baseInfo.allowEnd" />
          </template>
          <van-cell
            v-if="REAL_START_WHITE_LIST.includes(status)"
            title="实际开始时间"
            :value="baseInfo.realStart"
          />
          <van-cell
            v-if="REAL_END_WHITE_LIST.includes(status)"
            title="实际结束时间"
            :value="baseInfo.realEnd"
          />
        </van-collapse-item>
        <template v-if="showPassOrBask">
          <!-- <WorkticketPassOrBackView :pass-or-back-item="passOrBackItem" /> -->
          <van-action-sheet
            v-model="approveActionSheetShow"
            class="approve-action-sheet"
            :description="approveActionSheetTitle"
            :actions="approveActionSheetActions"
            cancel-text="返回"
            close-on-click-action
            :closeable="false"
            @select="AASSelect"
          />
        </template>
        <template v-if="showReferral">
          <WorkticketReferralView
            :id="curReferralWorkTypeId"
            ref="workticketReferralView"
            :ticket-id="baseInfo.id"
            :special-info="specialInfo"
            :referral-info="referralInfoProp"
            @changeCurStatus="changeCurStatus"
          />
        </template>
        <!-- <WorkticketSafeAnalysisView
          v-if="showSafeAnalysisItem"
          ref="WorkticketSafeAnalysisView"
          class="block-space_8_notLast"
          title="安全分析"
          name="安全分析"
          :ticket-id="baseInfo.id"
          :ticket-status="status"
          :user-edit-authority="baseInfo.edit"
          :editable.sync="editableSAV"
          @save="saveHandle"
          @edit="editHandle"
          @cancel="cancelHandle"
        /> -->

        <!-- 待安全交底 -->
        <template v-if="showSafeDisclosureSign">
          <WorkticketMeasureList
            ref="workticketMeasureList"
            :ticket-id="baseInfo.id"
            :work-type-list="baseInfo.workTypeList"
            :select-person-default-department="baseInfo.department"
          />
        </template>
        <template v-if="showSafeAnalysisItem">
          <!--作业类型列表-->
          <WorkticketTypeDetailItem
            v-for="(item, index) in baseInfo.workTypeList"
            :id="item.id"
            :key="item.specialId"
            class="block-space_8_notLast"
            :work-jsa-info="workJsaInfo"
            :ticket-id="baseInfo.id"
            :ticket-status="status"
            :special-id="item.specialId"
            :special-job-type="item.specialJobType"
            :title="item.name"
            :proposer="baseInfo.proposer"
            :type-name="item.typeName"
            :name="`${baseInfo.id}_workType_${index}`"
            :work-name="baseInfo.name"
            :gas-monitor="item.gasMonitor"
            :enable-approve="item.enableApprove"
            :approve-type="item.approveType"
            :gas-monitor-worker="item.gasMonitorWorker"
            :department="baseInfo.department"
            :user-edit-authority="baseInfo.edit"
            :original-data="item"
            :is-work-inspect="isWorkInspect"
            :work-info="baseInfo"
            :work-type-index="index"
            @changeCurStatus="changeCurStatus"
            @editable-change="val => specialTicketEditableChange(val, index)"
            @save="saveHandle"
            @edit="editHandle"
            @cancel="cancelHandle"
          />
        </template>

        <van-collapse-item
          v-if="showSafeDisclosureItem"
          class="block-space_8_notLast"
          title="安全交底"
          name="安全交底"
          :border="false"
          :is-link="false"
        >
          <template #value>
            <van-button
              class="safe-btn"
              type="info"
              round
              @click.stop="inventory"
              >安全交底清单</van-button
            >
          </template>
          <WorkticketSafedDsclosureView
            ref="WorkticketSafedDsclosureView"
            :ticket-id="baseInfo.id"
            :ticket-status="status"
            :is-all-sign.sync="isAllSign"
            :affect-flag="baseInfo.affectFlag"
            :origin-status="baseInfo.status"
            :is-equipment-safety="isEquipmentSafety"
          />
        </van-collapse-item>
        <WorkticketAttachmentView
          v-if="showWorkticketAttachmentItem"
          ref="WorkticketAttachmentView"
          :title="
            status == WORKTICKET_STATUS.Working ? '添加作业记录' : '作业记录'
          "
          name="作业记录"
          :ticket-id="baseInfo.id"
          :ticket-status="status"
          :user-edit-authority="baseInfo.edit"
          :editable.sync="editableAV"
          @save="saveHandle"
          @cancel="cancelHandle"
          @edit="editHandle"
        />
        <van-collapse-item
          v-if="ticketEvaluate.workerList && ticketEvaluate.workerList.length"
          class="block-space_8_notLast"
          style="border-bottom: #eceef2 solid 8px;"
          title="作业验收"
          name="作业验收"
          :border="false"
          :is-link="false"
        >
          <template #value>
            <van-button
              class="safe-btn"
              type="info"
              round
              @click.stop="$refs.workEvaluation.open(false)"
              >作业评价</van-button
            >
          </template>
          <van-cell
            v-if="ticketEvaluate.evaluateResult"
            class="evaluate-result"
            title="综合评价"
            :value="ticketEvaluate.evaluateResult"
          />
          <van-cell title="验收人">
            <template #right-icon>
              <Tag
                v-for="(item, index) of ticketEvaluate.workerList"
                :key="index"
                :type="tagTypeSwitch(item)"
                :tick="!!item.fileId"
                @click.native="previewSign(item.fileId)"
                >{{ item.name }}
              </Tag>
            </template>
          </van-cell>
        </van-collapse-item>
        <van-collapse-item
          v-if="personModifyRecords.length && showSafeAnalysisItem"
          class="block-space_8_notLast"
          title="人员变更记录"
          name="人员变更记录"
          :border="false"
          :is-link="false"
        >
          <ul class="person-change-record-box">
            <li
              v-for="(item, k) of personModifyRecords"
              :key="k"
              class="person-change-record"
            >
              <p class="title">
                {{ item.workTypeName }} - {{ item.wokerType }}
              </p>
              <p class="row">
                <span class="label">变更前人员：</span>{{ item.beforePerson }}
              </p>
              <p class="row">
                <span class="label">变更后人员：</span>{{ item.afterPerson }}
              </p>
              <p class="row">
                <span class="label">变更原因：</span>{{ item.modifyCause }}
              </p>
              <p class="row">
                <span class="label">安全交底确认：</span>
                <span
                  v-for="(p, k) of item.safetyList"
                  :key="k"
                  class="name-icon-box"
                >
                  <span>{{ p.name }}</span>
                  <van-icon
                    class="qianming"
                    class-prefix="iconfont"
                    name="icoqianming1"
                    @click="ImagePreview([`${fileUrl}${p.fileId}`])"
                  />
                </span>
              </p>
              <p
                v-if="localSectionChiefFlag && item.wokerType === '监护人'"
                class="row"
              >
                <span class="label">属地工段长：</span>
                <span
                  v-for="(p, k) of item.localSectionChiefList"
                  :key="k"
                  class="name-icon-box"
                >
                  <span>{{ p.name }}</span>
                  <van-icon
                    class="qianming"
                    class-prefix="iconfont"
                    name="icoqianming1"
                    @click="ImagePreview([`${fileUrl}${p.fileId}`])"
                  />
                </span>
              </p>
              <p class="date">{{ item.cuserName }} - {{ item.ctime }}</p>
            </li>
          </ul>
        </van-collapse-item>
      </van-collapse>

      <bottom-fixed-box>
        <template v-if="isWorkInspect">
          <van-button
            v-if="isChecked"
            class="btn-default"
            @click="onGoInspect('record')"
            >检查记录</van-button
          >
          <van-button class="btn-primary" @click="onGoInspect('inspect')"
            >监督检查</van-button
          >
        </template>
        <template v-else>
          <template v-if="showSafeDisclosureSign">
            <!-- <van-button class="btn-primary" @click="sign(false)"
            >部门签字</van-button
          >
          <van-button
            v-if="hasContractor"
            class="btn-primary"
            @click="sign(true)"
            >承包商签字</van-button
          > -->
            <van-button class="btn-primary" @click="sign(true)"
              >涉及</van-button
            >
            <van-button class="btn-primary" @click="sign(false)"
              >不涉及</van-button
            >
            <van-button class="btn-default" @click="submitSign"
              >确认</van-button
            >
          </template>
          <van-button
            v-if="
              isBy &&
                status === WORKTICKET_STATUS.Draft &&
                baseInfo.cuserId == userInfo.id
            "
            class="btn-primary"
            @click="ticketRecall"
            >撤 回
          </van-button>
          <van-button
            v-if="bottomCancelBtnText"
            :class="cancelBtnClassSwitch"
            class="btn-default"
            @click="handlerCancel"
            >{{ bottomCancelBtnText }}
          </van-button>
          <van-button
            v-if="bottomOtherBtnText"
            :class="otherBtnClassSwitch"
            @click="handlerOther"
            >{{ bottomOtherBtnText }}
          </van-button>
          <van-button
            v-if="bottomConfirmBtnText"
            :class="submitBtnDisabled ? 'btn-disabled' : 'btn-primary'"
            @click="handlerConfirm"
            >{{ bottomConfirmBtnText }}
          </van-button>
          <van-button
            v-if="actionBtnStop || deviateAuth || actionBtnExtensionRequest"
            class="btn-default"
            style="border: 0; background: transparent"
            @click="moreBtn"
          >
            <van-icon
              class-prefix="iconfont"
              name="zhankai2"
              size="26"
              color="#3278ff"
            />
          </van-button>
        </template>
      </bottom-fixed-box>
      <van-action-sheet
        v-model="actionsShow"
        :actions="actions"
        close-on-click-action
        @select="actionsSltClick"
      />
      <safe-inventory
        :visible="safeInventoryVb"
        position="bottom"
        title="安全交底清单"
        type="textarea"
        :autosize="{
          // minHeight: 26,
          maxHeight: 126
        }"
        :mode-type="
          status === WORKTICKET_STATUS.Safety_disclosureing ? '0' : '2'
        "
        :rows="1"
        :ticket-id="baseInfo.id"
        @confirm="safeInventoryConfirm"
        @close="safeInventoryVb = false"
      />
    </div>
    <WorkEvaluation ref="workEvaluation" :data="ticketEvaluate" />
    <DialogApproveAll ref="dialogApproveAll" :data="approveNodes" />
    <ConfirmationCard ref="confirmationCard" />
    <DialogUpgradation v-if="isBy" ref="dialogUpgradation" />
    <DialogApprove v-if="isBy" ref="dialogApprove" />
    <DialogEquipmentSafetyAccomplish
      v-if="isBy"
      ref="dialogEquipmentSafetyAccomplish"
    />
  </div>
</template>

<script>
import WorkticketReferralView from "@/components/workticket/WorkticketReferralView";
// import WorkticketPassOrBackView from "@/components/workticket/WorkticketPassOrBackView";
import WorkticketMeasureList from "./WorkticketMeasureList";
import WorkticketHeader from "@/components/header/WorkticketHeader";
import {
  WORKTICKET_STATUS,
  WORKTICKET_STATUS_NAME,
  WORKTICKET_TYPE,
  REAL_START_WHITE_LIST,
  REAL_END_WHITE_LIST,
  GUARDIAN_BTN_STATUS_MAP_WHITE_LIST
} from "@/views/workticket/utils/constant";
import BottomFixedBox from "@/components/general/BottomFixedBox";
import InputDialog from "@/components/dialog/InputDialog";
import InputDialogComplex from "@/components/dialog/InputDialogComplex";
import InputDialogMultiplePerson from "@/components/dialog/InputDialogMultiplePerson";
import {
  appWorkSafekDisclosure,
  appWorkStartApprove,
  // finishApproving,
  finishGasMonitor,
  finishSafetyAnalysis,
  finishWorking,
  finishWorkingDeliver,
  getWorkticketBaseInfo,
  startWorking,
  stopWorking,
  updateGoSafetyAnalysis,
  validateWorkWork,
  pauseWork,
  continueWork,
  closeWork,
  validateSigned,
  safetyMeasureSubmit,
  transferApprove,
  addWorkSignature,
  workticketBack,
  getWorkJsaInfo,
  getWorkSafeWork,
  delayWorkticket,
  deviateWorkticket,
  cancelWorkticket,
  checkWorkticket,
  getPersonModifyRecords,
  saveSafetyMeasureFile,
  addSpaceWorkRecord,
  getWorkticketTypeDetail,
  continueHighWork,
  getSpaceWorkRecordExit,
  getSpaceWorkRecord,
  getTicketEvaluate,
  getWorkNodes,
  getPauseTime,
  ticketRecall,
  validateWorkUpgradeStatus,
  getWorkUpgradeApproveStatus,
  startCraftOverhaulAnalyse,
  finishCraftOverhaulAnalyse,
  finishCraftOverhaulDisclosure,
  editEquipmentSafety
} from "@/api/workticket";
import { getSelectWorkTypes } from "@/api/psm";
import WorkticketTypeDetailItem from "@/components/workticket/WorkticketTypeDetailView";
// import WorkticketSafeAnalysisView from "@/components/workticket/WorkticketSafeAnalysisView";
import WorkticketSafedDsclosureView from "@/components/workticket/WorkticketSafedDisclosureView";
import WorkticketAttachmentView from "@/components/workticket/WorkticketAttachmentView";
import { mapState } from "vuex";
import esign from "@/components/esign";
import { getCheckStatusByWorkId } from "@/api/psm/workInspect";
import projectTextConfig from "@/utils/projectTextConfig";
import { uniqBy, maxBy } from "lodash";
import safeInventory from "./components/safeInventory";
import { ImagePreview } from "vant";
import { fileUrl } from "@/utils/constant";
import WorkEvaluation from "@/views/workticket/components/WorkEvaluation";
import DialogApproveAll from "@/views/workticket/components/DialogApproveAll";
import { deepClone } from "@/utils";
import ConfirmationCard from "@/views/workticket/components/ConfirmationCard";
import MaintenanceHours from "./components/MaintenanceHours";
import ProjectCategory from "./components/ProjectCategory";
import ProjectRiskLevel from "./components/ProjectRiskLevel";
import ProjectLeader from "./components/ProjectLeader";
import SafetyLeader from "./components/SafetyLeader";
import OverhaulLeader from "./components/OverhaulLeader";
import ProjectSource from "./components/ProjectSource";
import DialogUpgradation from "./components/DialogUpgradation";
import DialogApprove from "./components/DialogApprove";
import DialogEquipmentSafetyAccomplish from "./components/DialogEquipmentSafetyAccomplish";

export default {
  name: "WorkticketDetail",
  components: {
    WorkticketMeasureList,
    WorkticketAttachmentView,
    WorkticketSafedDsclosureView,
    // WorkticketSafeAnalysisView,
    WorkticketTypeDetailItem,
    BottomFixedBox,
    WorkticketHeader,
    WorkticketReferralView,
    safeInventory,
    // WorkticketPassOrBackView
    WorkEvaluation,
    DialogApproveAll,
    ConfirmationCard,
    MaintenanceHours,
    ProjectCategory,
    ProjectRiskLevel,
    ProjectLeader,
    SafetyLeader,
    OverhaulLeader,
    ProjectSource,
    DialogUpgradation,
    DialogApprove,
    DialogEquipmentSafetyAccomplish
  },
  inject: ["reload"],
  data() {
    return {
      projectTextConfig,
      projectName: window.globalConfig.VUE_APP_NAME,
      passOrBackItem: {},
      // 转审的详情信息
      referralInfoProp: {},
      id: "",
      status: "",
      status2: "",
      activeName: [],
      editingSafe: false,
      editingWork: false,
      baseInfo: {
        // approver: [],
        approverList: [],
        proposer: "",
        proposerList: [],
        supervisor: "",
        supervisorList: [],
        relationList: []
      },
      workTypeDetailList: [],
      WORKTICKET_STATUS,
      REAL_START_WHITE_LIST,
      REAL_END_WHITE_LIST,
      GUARDIAN_BTN_STATUS_MAP_WHITE_LIST,
      isAllSign: false,
      selectPersonVisible: false,
      checkedPersons: [],
      curReferralWorkTypeId: "", // 转审的小票id,
      specialInfo: {}, // 转审的信息
      editableSAV: false,
      specialTicketEditable: [],
      editableAV: false,
      approveActionSheetShow: false,
      approveActionSheetTitle: "",
      approveActionSheetActions: [
        { name: "通过", className: "approve-item pass" },
        {
          name: "退回" + projectTextConfig.TEXT_SAFETY_ANALYSIS_DELIVER,
          className: "approve-item reject"
        },
        { name: "退回申请环节", className: "approve-item reject" }
      ],
      workJsaInfo: null,
      // 是不是作业检查
      isWorkInspect: false,
      // 检查状态
      isChecked: false,
      actionsShow: false,
      actions: [
        { name: "终止", color: "#ee0a24", typeName: "stop" },
        { name: "偏离", typeName: "deviate" },
        { name: "延期申请", typeName: "extensionRequest" }
      ],
      personModifyRecords: [],
      fileUrl,
      safeInventoryVb: false,
      ticketEvaluate: {
        ticketId: this.id,
        evaluateQuotaList: [],
        evaluateRemark: "",
        evaluateResult: "",
        workerList: []
      },
      approveNodes: [],
      localSectionChiefFlag:
        window.globalConfig.VUE_APP_WORK_LOCAL_SECTION_CHIEF === "true"
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo,
      btnAuth: state => state.menu.btnAuth
    }),
    // 是否有承包商
    hasContractor() {
      const cl = this.baseInfo.workTypeList.filter(
        a => a.contractorStatus == 1
      );
      return cl.length > 0;
    },
    guardianBtnWhiteList() {
      return GUARDIAN_BTN_STATUS_MAP_WHITE_LIST[this.status] || "";
    },
    workTypeNames() {
      const { baseInfo } = this;
      return baseInfo.workTypeList
        ? baseInfo.workTypeList.map(item => item.name).join(",")
        : "";
    },
    title() {
      if (
        [
          WORKTICKET_STATUS.Craft_safety_disclosure,
          WORKTICKET_STATUS.Overhaul_safety_disclosure
        ].includes(this.baseInfo.status)
      ) {
        return WORKTICKET_STATUS_NAME[this.baseInfo.status];
      }
      let statusName = WORKTICKET_STATUS_NAME[this.status];
      return statusName ? statusName : "作业许可详情";
    },
    // 显示安全措施签字确认页面
    showSafeDisclosureSign() {
      return this.status == WORKTICKET_STATUS.Safety_disclosure_sign;
    },
    // 是否显示通过/退回页面
    showPassOrBask() {
      return this.status == WORKTICKET_STATUS.ApprovingPassOrBack;
    },
    // 显示转审页面
    showReferral() {
      return this.status == WORKTICKET_STATUS.ReferralApproving;
    },
    //显示安全分析
    showSafeAnalysisItem() {
      return (
        [
          WORKTICKET_STATUS.Gas_monitor,
          WORKTICKET_STATUS.Safety_disclosure,
          WORKTICKET_STATUS.Analysing,
          WORKTICKET_STATUS.Approving,
          WORKTICKET_STATUS.Working,
          WORKTICKET_STATUS.Delivering,
          WORKTICKET_STATUS.Qualified,
          WORKTICKET_STATUS.Unqualified,
          WORKTICKET_STATUS.ApprovingPassOrBack,
          WORKTICKET_STATUS.PendingJob,
          WORKTICKET_STATUS.Paused,
          WORKTICKET_STATUS.Close,
          WORKTICKET_STATUS.Craft_analysing,
          WORKTICKET_STATUS.Craft_safety_disclosure,
          WORKTICKET_STATUS.Overhaul_analysing,
          WORKTICKET_STATUS.Overhaul_safety_disclosure
        ].findIndex(item => item == this.status) != -1
      );
    },
    showBaseInfo() {
      return (
        [
          WORKTICKET_STATUS.Safety_disclosureing,
          WORKTICKET_STATUS.Safety_disclosure_sign,
          WORKTICKET_STATUS.ReferralApproving // 转审
          // WORKTICKET_STATUS.ApprovingPassOrBack // 通过/退回
        ].findIndex(item => item == this.status) == -1
      );
    },

    //显示安全交底
    showSafeDisclosureItem() {
      return (
        [
          WORKTICKET_STATUS.Approving,
          WORKTICKET_STATUS.Working,
          WORKTICKET_STATUS.Delivering,
          WORKTICKET_STATUS.Safety_disclosureing,
          WORKTICKET_STATUS.Qualified,
          WORKTICKET_STATUS.Unqualified,
          // WORKTICKET_STATUS.Stop
          WORKTICKET_STATUS.PendingJob,
          WORKTICKET_STATUS.Paused,
          WORKTICKET_STATUS.Close,
          WORKTICKET_STATUS.ApprovingPassOrBack
        ].findIndex(item => item == this.status) != -1
      );
    },
    showWorkticketAttachmentItem() {
      return (
        [
          WORKTICKET_STATUS.Working,
          WORKTICKET_STATUS.Delivering,
          WORKTICKET_STATUS.Qualified,
          WORKTICKET_STATUS.Unqualified,
          // WORKTICKET_STATUS.Stop
          WORKTICKET_STATUS.Paused,
          WORKTICKET_STATUS.Close
        ].findIndex(item => item == this.status) != -1
      );
    },

    bottomConfirmBtnText() {
      // 状态对应的功能按钮
      const obj = {
        [WORKTICKET_STATUS.Draft]: "通过",
        [WORKTICKET_STATUS.Safety_analysis]: "安全分析",
        [WORKTICKET_STATUS.Analysing]: "完成分析",
        [WORKTICKET_STATUS.Gas_monitor]: "完成",
        [WORKTICKET_STATUS.Safety_disclosure]: "安全交底",
        [WORKTICKET_STATUS.Safety_disclosureing]: "提交",
        // [WORKTICKET_STATUS.Approving]: "开始作业",
        [WORKTICKET_STATUS.PendingJob]: "开始作业",
        [WORKTICKET_STATUS.Working]: "完成",
        [WORKTICKET_STATUS.Paused]: "完成",
        [WORKTICKET_STATUS.Delivering]: "验收合格",
        [WORKTICKET_STATUS.Unqualified]: "验收合格",
        [WORKTICKET_STATUS.Qualified]: "关闭",
        [WORKTICKET_STATUS.Craft_safety_analysis]: "安全分析",
        [WORKTICKET_STATUS.Craft_analysing]: "完成分析",
        [WORKTICKET_STATUS.Craft_safety_disclosure]: "安全交底",
        [WORKTICKET_STATUS.Overhaul_safety_analysis]: "安全分析",
        [WORKTICKET_STATUS.Overhaul_analysing]: "完成分析",
        [WORKTICKET_STATUS.Overhaul_safety_disclosure]: "安全交底"
      };
      // 待安全交底环节 并且当前登录用户为安全作业分析负责人的才显示下面的安全交底按钮
      const name = obj[this.status] || "";
      if (this.status === WORKTICKET_STATUS.Safety_disclosure) {
        if (
          this.baseInfo &&
          String(this.userInfo.id) === this.baseInfo.supervisor
        ) {
          return name;
        }
        return "";
      } else if (this.status === WORKTICKET_STATUS.Draft) {
        // 如果当前是待开票审批, 只有当前登录人为作业批准人的时候才可以审批或者退回
        // this.userInfo.id === this.baseInfo.approver
        if (this.baseInfo && this.baseInfo.edit) {
          return name;
        }
        return "";
      } else if (
        [
          WORKTICKET_STATUS.Craft_safety_analysis,
          WORKTICKET_STATUS.Craft_analysing,
          WORKTICKET_STATUS.Craft_safety_disclosure
        ].includes(this.status)
      ) {
        // 工艺安全分析 当前登录用户为安全负责人才显示按钮
        if (
          this.baseInfo &&
          String(this.userInfo.id) === this.baseInfo.jsaSafetyLeaderList[0]?.id
        ) {
          return name;
        }
        return "";
      } else if (
        [
          WORKTICKET_STATUS.Overhaul_safety_analysis,
          WORKTICKET_STATUS.Overhaul_analysing,
          WORKTICKET_STATUS.Overhaul_safety_disclosure
        ].includes(this.status)
      ) {
        // 检修安全分析 当前登录用户为检修负责人才显示按钮
        if (
          this.baseInfo &&
          String(this.userInfo.id) ===
            this.baseInfo.jsaOverhaulLeaderList[0]?.id
        ) {
          return name;
        }
        return "";
      } else {
        if (!this.guardianBtnWhiteList) return name;
        if (this.guardianBtnWhiteList.includes(name)) {
          return this.baseInfo.edit ? name : "";
        }
        return name;
      }
    },
    bottomCancelBtnText() {
      const obj = {
        [WORKTICKET_STATUS.Draft]: "退回",
        // [WORKTICKET_STATUS.Working]: "终止",
        // [WORKTICKET_STATUS.Paused]: "终止",
        [WORKTICKET_STATUS.Delivering]: "验收不合格",
        [WORKTICKET_STATUS.Unqualified]: "验收不合格",
        [WORKTICKET_STATUS.Safety_analysis]: "取消",
        [WORKTICKET_STATUS.Analysing]: "取消",
        [WORKTICKET_STATUS.Gas_monitor]: "取消",
        [WORKTICKET_STATUS.Safety_disclosure]: "取消",
        [WORKTICKET_STATUS.Approving]: "取消"
      };
      const arr = [
        WORKTICKET_STATUS.Safety_analysis,
        WORKTICKET_STATUS.Analysing,
        WORKTICKET_STATUS.Gas_monitor,
        WORKTICKET_STATUS.Safety_disclosure,
        WORKTICKET_STATUS.Approving
      ];
      const name = obj[this.status] || "";
      if (this.status === WORKTICKET_STATUS.Draft) {
        // 如果当前是待开票审批, 只有当前登录人为作业批准人的时候才可以审批或者退回
        // this.userInfo.id === this.baseInfo.approver
        if (this.baseInfo && this.baseInfo.edit) {
          return name;
        }
        return "";
      }
      if (arr.includes(this.status)) {
        if (
          this.btnAuth.cancel &&
          (this.userInfo.id === this.baseInfo.cuserId ||
            this.userInfo.id === this.baseInfo.supervisor)
        ) {
          return name;
        }
        return "";
      }
      if (!this.guardianBtnWhiteList) return name;
      if (this.guardianBtnWhiteList.includes(name)) {
        return this.baseInfo.edit ? name : "";
      }
      return name;
    },
    bottomOtherBtnText() {
      const obj = {
        [WORKTICKET_STATUS.Draft]: "取消",
        [WORKTICKET_STATUS.Working]: "暂停",
        [WORKTICKET_STATUS.Paused]: "继续"
      };
      const name = obj[this.status] || "";
      if (!this.guardianBtnWhiteList) return name;
      if (this.guardianBtnWhiteList.includes(name)) {
        return this.baseInfo.edit ? name : "";
      }
      return name;
    },
    actionBtnStop() {
      const obj = {
        [WORKTICKET_STATUS.Working]: "终止",
        [WORKTICKET_STATUS.Paused]: "终止"
      };
      const name = obj[this.status] || "";
      if (!this.guardianBtnWhiteList) return name;
      if (this.guardianBtnWhiteList.includes(name)) {
        return this.baseInfo.edit ? name : "";
      }
      return name;
    },
    actionBtnExtensionRequest() {
      const arr = [WORKTICKET_STATUS.Working, WORKTICKET_STATUS.Paused];
      if (arr.includes(this.status) && this.btnAuth.delay) {
        return this.baseInfo.edit;
      }
      return false;
    },
    deviateAuth() {
      const arr = [
        WORKTICKET_STATUS.Safety_disclosure,
        WORKTICKET_STATUS.Working,
        WORKTICKET_STATUS.Paused
      ];
      return (
        this.btnAuth.deviate &&
        arr.includes(this.status) &&
        this.userInfo.id === this.baseInfo.cuserId
      );
    },
    //是否有带气体检测的作业类型：动火、受限空间workTypeList
    hasGasCheckWork() {
      let { baseInfo } = this;
      if (
        !baseInfo ||
        !baseInfo.workTypeList ||
        baseInfo.workTypeList.length == 0
      ) {
        return false;
      }
      return !!baseInfo.workTypeList.find(
        item =>
          !![WORKTICKET_TYPE.fire, WORKTICKET_TYPE.space].find(
            ite => ite == item.specialJobType
          )
      );
    },
    //提交按钮判断是否可点击
    submitBtnDisabled() {
      let {
        status,
        isAllSign,
        editableSAV,
        specialTicketEditable,
        editableAV
      } = this;
      //安全交底状态，没有完全签名不能提交
      const isAllSignBySD =
        WORKTICKET_STATUS.Safety_disclosureing == status && !isAllSign;
      // 安全分析中，各区域有编辑态，不能完成安全分析
      const allSTEditable = specialTicketEditable.some(i => i);
      const arr = [
        WORKTICKET_STATUS.Analysing,
        WORKTICKET_STATUS.Safety_disclosure
      ];
      const editableBySA =
        arr.find(i => i == status) && (editableSAV || allSTEditable);
      // 作业中，添加作业记录区域如果是编辑态，页面底端的【终止】、【暂停】、【完成】按钮置灰
      const workLogEditable = WORKTICKET_STATUS.Working == status && editableAV;
      if (isAllSignBySD || editableBySA || workLogEditable) {
        return true;
      }

      return false;
    },
    otherBtnClassSwitch() {
      let { status, editableAV } = this;
      // 作业中，添加作业记录区域如果是编辑态，页面底端的【终止】、【暂停】、【完成】按钮置灰
      const workLogEditable = WORKTICKET_STATUS.Working == status && editableAV;
      if (workLogEditable) return "btn-disabled";
      if (WORKTICKET_STATUS.Paused === status) return "btn-primary";
      return "btn-default";
    },
    cancelBtnClassSwitch() {
      let { status, editableAV } = this;
      // 作业中，添加作业记录区域如果是编辑态，页面底端的【终止】、【暂停】、【完成】按钮置灰
      const workLogEditable = WORKTICKET_STATUS.Working == status && editableAV;
      const draft = WORKTICKET_STATUS.Draft == status;
      if (draft) {
        return "btn-primary";
      }
      return workLogEditable ? "btn-disabled" : "btn-default";
    },
    isEquipmentSafety() {
      const isEquipmentSafety =
        this.baseInfo.workTypeList[0]?.specialJobType ===
        WORKTICKET_TYPE["equipment-safety"];
      return isEquipmentSafety;
    }
  },
  watch: {
    jsaChecked(newV) {
      if (newV.length === this.jsaList.length) {
        this.jsaCheckedAll = true;
      } else {
        this.jsaCheckedAll = false;
      }
    },
    // activeName(newValue, oldValue) {
    //   console.log('activeName>>>>>>>>>>>>')
    //   this.$broadcast("WorkticketTypeDetailItem", "activeChange", [
    //     ...this.activeName
    //   ]);
    // },
    /* status(val) {
      if (val == WORKTICKET_STATUS.ApprovingPassOrBack) return;
      if (val == WORKTICKET_STATUS.Safety_disclosureing) {
        this.activeName = ["安全交底"];
      } else if (val == WORKTICKET_STATUS.Safety_disclosure) {
        this.activeName = [];
      } else if (val == WORKTICKET_STATUS.Analysing) {
        this.activeName = ["安全分析"];
      } else if (val == WORKTICKET_STATUS.Gas_monitor) {
        this.activeName = [];
      } else if (val == WORKTICKET_STATUS.Approving) {
        this.activeName = [];
      } else if (val == WORKTICKET_STATUS.PendingJob) {
        this.activeName = [];
      } else if (val == WORKTICKET_STATUS.Working) {
        this.activeName = ["作业记录"];
        this.$nextTick(() => {
          window.scrollTo(0, document.body.scrollHeight);
        });
      } else {
        this.activeName = ["申请信息"];
      }
    },*/
    "$route.params.id": {
      handler(val) {
        this.initPage();
      }
    }
  },
  created: function() {
    this.isWorkInspect = this.$route.query?.mode === "inspect";
    if (this.isWorkInspect) {
      this.getCheckStatus();
    }
  },
  mounted: function() {
    this.initPage();
    this.$nextTick();
    window.addEventListener("scroll", this.initScrollTitlePosition);
    this.initTitleClickPosition();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.initScrollTitlePosition);
  },
  methods: {
    async getPersonModifyRecords() {
      let res = await getPersonModifyRecords(this.id);
      res = res.map(item => {
        item.safetyList = [];
        item.localSectionChiefList = [];
        item.workerList.forEach(worker => {
          switch (worker.workerType) {
            // 属地工段长
            case "3402":
              item.localSectionChiefList.push(worker);
              break;
            default:
              item.safetyList.push(worker);
          }
        });
        return item;
      });
      this.personModifyRecords = res;
    },
    async getTicketEvaluate() {
      this.ticketEvaluate = await getTicketEvaluate(this.id);
    },
    ImagePreview,
    safeInventoryConfirm() {
      if (this.status === WORKTICKET_STATUS.Safety_disclosureing) {
        this.$refs.WorkticketSafedDsclosureView.init();
      }
    },
    inventory() {
      this.safeInventoryVb = true;
    },
    gotoDetail(id) {
      this.$router.push({
        name: "workUnusualWarning",
        params: { id }
      });
    },
    timeUpSwitch({ days, hours, minutes, seconds }) {
      return !(days + hours + minutes + seconds);
    },
    numberSwitch(num) {
      let str = String(num);
      if (str.length < 2) {
        str = `0${str}`;
      }
      return str;
    },
    moreBtn() {
      const arr = [
        { name: "终止", typeName: "stop", show: this.actionBtnStop },
        { name: "偏离", typeName: "deviate", show: this.deviateAuth },
        {
          name: "延期申请",
          typeName: "extensionRequest",
          show: this.actionBtnExtensionRequest
        }
      ];
      this.actions = arr.filter(i => i.show);
      this.actionsShow = true;
    },
    actionsSltClick(act, idx) {
      switch (act.typeName) {
        case "stop":
          this.handlerCancel();
          break;
        case "extensionRequest":
          this.extensionRequest();
          break;
        case "deviate":
          this.deviate();
          break;
      }
    },
    async extensionRequest() {
      const data = await getWorkSafeWork(this.id);
      let work = data.find(i => i.personType === "1")?.workerList || [];
      work = uniqBy(
        work.reduce((acc, cur) => {
          acc.push(...(cur?.workerList || []));
          return acc;
        }, []),
        "personId"
      );
      work = work.map(i => {
        return {
          type: "0", // 如果需要改变type则需要从上面接口数据里获取
          fileId: "",
          personType: "3201",
          personId: i.personId,
          personName: i.personName,
          id: i.personId,
          name: i.personName,
          ticketId: this.id,
          specialWorkId: i.specialId
        };
      });
      const time = await this.workTypeListMinTime();
      const endDate = new Date(Date.parse(this.baseInfo.allowStart) + time)
        .toLocaleDateString("zh-cn", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit"
        })
        .replace(/\//g, "-");
      const endTime = new Date(
        Date.parse(this.baseInfo.allowStart) + time
      ).toLocaleTimeString("en-GB", {
        hour12: false
      });
      const dateRange = [this.baseInfo.allowEnd, `${endDate} ${endTime}`];
      this.InputDialogComplexFun(
        "延期申请",
        (res, arg) =>
          new Promise(rlv => {
            rlv(res, arg);
          }),
        [],
        (res, arg, apiArg) => {
          const personInfo = (res?.choosePerson || []).map(i => {
            return {
              ticketId: this.id,
              id: i.value,
              name: i.name,
              type: i._mechanismType,
              personId: i.value,
              personType: "3202",
              fileId: i.fileId
            };
          });
          delayWorkticket(this.id, {
            status: this.status,
            remark: res.remark,
            delayEndTime: res.delayEndTime,
            workerList: [...personInfo, ...work],
            signatureList: [...personInfo, ...work]
          })
            .then(res => {
              this.$toast.success("操作成功");
              this.reload();
            })
            .catch(error => {
              this.$toast.fail("请求失败,请重试");
            });
        },
        true,
        this.id,
        this.baseInfo.department,
        true,
        true,
        true,
        "批准",
        "批准人(单选)",
        false,
        false,
        "",
        500,
        work.length ? work : [],
        "作业负责",
        false,
        {},
        [],
        "3202",
        dateRange
      );
    },
    async deviate() {
      const data = await getWorkSafeWork(this.id);
      let work = data.find(i => i.personType === "1")?.workerList || [];
      work = uniqBy(
        work.reduce((acc, cur) => {
          acc.push(...(cur?.workerList || []));
          return acc;
        }, []),
        "personId"
      ).filter(item => Number(item.current) === 1);
      work = work.map(i => {
        return {
          type: "0", // 如果需要改变type则需要从上面接口数据里获取
          fileId: "",
          personType: "311",
          personId: i.personId,
          personName: i.personName,
          id: i.personId,
          name: i.personName,
          ticketId: this.id,
          specialWorkId: i.specialId
        };
      });
      this.InputDialogComplexFun(
        "偏离原因及可能产生的后果",
        (res, arg) =>
          new Promise(rlv => {
            rlv(res, arg);
          }),
        [],
        (res, arg, apiArg) => {
          const personInfo = (res?.choosePerson || []).map(i => {
            return {
              ticketId: this.id,
              id: i.value,
              name: i.name,
              type: i._mechanismType,
              personId: i.value,
              personType: "312",
              fileId: i.fileId
            };
          });
          deviateWorkticket(this.id, {
            remark: res.remark,
            status: this.status,
            signs: [...personInfo, ...work],
            workerList: [...personInfo, ...work]
          })
            .then(res => {
              this.$toast.success("操作成功");
              this.reload();
            })
            .catch(error => {
              this.$toast.fail("请求失败,请重试");
            });
        },
        true,
        this.id,
        this.baseInfo.department,
        true,
        true,
        true,
        "批准",
        "批准人(单选)",
        false,
        false,
        "",
        500,
        work.length ? work : [],
        "作业负责",
        false,
        {},
        [],
        "312",
        []
      );
    },
    async workTypeListMinTime() {
      const workTypeSltData = await getSelectWorkTypes(this.baseInfo.orgCode);
      const choose = workTypeSltData.filter(item =>
        this.baseInfo.workTypeList.find(c => c.id === item.id && item.times)
      );
      const obj = {
        h: 3600000,
        d: 86400000
      };
      const millisecondList = choose
        .map(item => Number(item.times) * obj[item.timesUnit])
        .sort((a, b) => a - b);
      if (!millisecondList[0] && millisecondList[0] !== 0) {
        millisecondList.push(99999999999999);
      }
      return millisecondList[0];
    },
    saveHandle(e) {
      this.initTitlePosition(e);
    },
    editHandle() {},
    async cancelHandle() {
      await this.$nextTick();
      this.initScrollTitlePosition();
    },
    hasClass(ele, className) {
      if (!ele.className) {
        return false;
      }
      return ele.className.split(" ").indexOf(className) > -1;
    },
    findParents(ele) {
      const parents = [];
      const fn = ele => {
        if (ele) {
          parents.push(ele);
          fn(ele.parentNode);
        }
      };
      fn(ele);
      return parents;
    },
    findParent(e, className) {
      let ele = null;
      const parents = this.findParents(e.target);
      for (let i = 0; i < parents.length; i++) {
        if (this.hasClass(parents[i], className)) {
          ele = parents[i];
          break;
        }
      }
      return ele;
    },
    initTitleClickPosition() {
      document.addEventListener("click", e => {
        this.initTitlePosition(e);
      });
    },
    initTitlePosition(e) {
      const topBoxHeight = document.querySelector(".top-box");
      const titleElement = this.findParent(e, "van-collapse-item__title");
      if (titleElement) {
        const boxEle = this.findParent(e, "edit-button-editing");
        if (boxEle) {
          window.scroll({
            top: boxEle.offsetTop + topBoxHeight.offsetHeight,
            behavior: "smooth"
          });
        }
      }
    },
    initScrollTitlePosition(e) {
      const headerHeight =
        document.querySelector(".van-nav-bar")?.offsetHeight || 0;
      const titleHeight =
        document.querySelector(".van-collapse-item__title")?.offsetHeight || 0;
      let index = 0;

      document.querySelectorAll(".van-collapse-item").forEach(item => {
        const titleEle = item.querySelector(".van-collapse-item__title");
        if (this.hasClass(item, "edit-button-editing")) {
          const needFixed =
            item.getBoundingClientRect().top -
              headerHeight -
              index * titleHeight <=
            0;
          if (needFixed) {
            titleEle.style.top = index * titleHeight + headerHeight + "px";
            titleEle.style.position = "fixed";
            item.style.zIndex = 100 - index;
            index++;
          } else {
            titleEle.style.position = "absolute";
            titleEle.style.top = 0;
            item.style.zIndex = 0;
          }
        } else {
          titleEle.style.position = "absolute";
          titleEle.style.top = 0;
          item.style.zIndex = 0;
        }
      });
    },

    async getCheckStatus() {
      try {
        this.isChecked = await getCheckStatusByWorkId(this.$route.params.id);
      } catch (e) {
        console.log(e);
      }
    },
    onGoInspect(type) {
      const { id } = this.$route.params;
      if (type === "record") {
        this.$router.push({ name: "WorkInspectAddRecord", params: { id } });
      }
      if (type === "inspect") {
        this.$router.push({ name: "WorkInspectAdd", params: { id } });
      }
    },
    initPage() {
      let { id } = this.$route.params;
      this.id = id;

      this.loadData();

      this.$on("clickSaveBtn", name => {
        //监听编辑按钮，打开折叠
        if (this.activeName.indexOf(name) == -1) {
          this.activeName = [name];
        }
      });

      this.$on("changeStatus", status => {
        this.status = status;
      });
    },
    changeCurStatus(status, payload, fenceCoord) {
      if (payload) {
        // 如果是转审
        if (payload.btnType === "referral-approving") {
          this.specialInfo = payload;
          this.curReferralWorkTypeId = payload.specialId;
          this.transferApprove(() => {
            this.status = status;
          });
        } else if (status === WORKTICKET_STATUS.ApprovingPassOrBack) {
          this.status = status;
          this.passOrBackItem = { ...payload, fenceCoord };
          this.approveActionSheetTitle = payload.nodeName;
          // 审批方式【0：不同账号逐级审批；1：同一账号会签】
          switch (payload.approveWay) {
            case 0:
              if (
                this.baseInfo.workTypeList[0]?.specialJobType ===
                WORKTICKET_TYPE["equipment-safety"]
              ) {
                this.approveActionSheetActions[1].name = "退回待检修安全交底";
              } else {
                this.approveActionSheetActions[1].name = `退回${projectTextConfig.TEXT_SAFETY_ANALYSIS_DELIVER}`;
              }
              this.approveActionSheetShow = true;
              break;
            case 1:
              this.$refs.dialogApproveAll.open(
                payload.title,
                payload.specialId,
                this.baseInfo.workTypeList[0]?.specialJobType ===
                  WORKTICKET_TYPE["equipment-safety"]
              );
              break;
            default:
              console.error("approveWay 无效");
          }
        } else {
          this.status = status;
        }
      } else {
        this.status = status;
      }
    },
    AASSelect(act) {
      switch (act.name) {
        case "通过":
          const successCb = remark => {
            this.dialogSuccessFn(null, {}, () => {
              esign.show(file => {
                const params = {
                  fileId: file.id,
                  personId: this.userInfo.id,
                  personType: 6,
                  ticketId: this.passOrBackItem.ticketId,
                  type: 0,
                  approveType: this.passOrBackItem.approveType,
                  specialId: this.passOrBackItem.specialId,
                  remark,
                  latitude: this.passOrBackItem.fenceCoord?.latitude,
                  longitude: this.passOrBackItem.fenceCoord?.longitude
                };
                addWorkSignature(params)
                  .then(() => {
                    this.$toast.success("操作成功");
                    //审批签名成功，刷新当前页面
                    this.reload();
                  })
                  .catch(() => {
                    this.$toast.fail("操作失败，请重试");
                  });
              });
            });
          };
          InputDialog.show({
            title: "审批通过意见",
            successCb,
            ticketId: this.id,
            department: this.baseInfo.department,
            hasInput: true,
            isRequired: false,
            message: "同意作业"
          });
          break;

        case `退回${projectTextConfig.TEXT_SAFETY_ANALYSIS_DELIVER}`:
          this.InputDialogFun(
            "退回原因",
            () =>
              new Promise(rlv => {
                rlv();
              }),
            [],
            (res, arg) => {
              workticketBack(this.passOrBackItem.ticketId, {
                remark: arg[0],
                specialId: this.passOrBackItem.specialId,
                status:
                  this.baseInfo.workTypeList[0]?.specialJobType ===
                  WORKTICKET_TYPE["equipment-safety"]
                    ? 20
                    : 5
              })
                .then(res => {
                  if (res) {
                    this.$toast("退回成功");
                    this.reload();
                    return;
                  }
                  this.$toast("退回失败, 请重试");
                })
                .catch(e => {
                  this.$toast("退回失败, 请重试");
                });
            },
            false,
            this.id,
            this.baseInfo.department,
            true,
            true
          );
          break;

        case "退回申请环节":
          this.InputDialogFun(
            "退回原因",
            () =>
              new Promise(rlv => {
                rlv();
              }),
            [],
            (res, arg) => {
              workticketBack(this.passOrBackItem.ticketId, {
                remark: arg[0],
                specialId: this.passOrBackItem.specialId,
                status: 14
              })
                .then(res => {
                  if (res) {
                    this.$toast("退回成功");
                    this.$router.replace({
                      name: "workticketAdd",
                      params: {
                        id: this.id
                      }
                    });
                    return;
                  }
                  this.$toast("退回失败, 请重试");
                })
                .catch(e => {
                  this.$toast("退回失败, 请重试");
                });
            },
            false,
            this.id,
            this.baseInfo.department,
            true,
            true
          );
          break;
      }
    },
    specialTicketEditableChange(val, idx) {
      this.specialTicketEditable.splice(idx, 1, val);
    },
    // 获取转审详情
    transferApprove(cb) {
      transferApprove(this.curReferralWorkTypeId, {
        type: this.specialInfo.specialJobType
      })
        .then(res => {
          this.referralInfoProp = res;
          cb && cb();
        })
        .catch(e => {
          console.log("eeee", e);
        });
    },
    showPersonPopup(v) {
      this.selectPersonVisible = v;
    },
    onPersonConfirm(ids, list) {
      const person = list[0];
      // 如果没有选择人员则直接返回
      if (!person) {
        return;
      }
      // 跳转到签名页面
      esign.show(imgRes => {
        // id
        // name
        // size
        // uploadTime
        // LiYajie 根据选择的人员和签名发送请求保存,保存成功后返回到列表页
        // 根据选择的人员和签名发送请求保存,保存成功后返回到列表页
      });
    },
    // 签名
    sign(isContractor) {
      // 判断已选的措施中，变量控件中是否录入内容
      const measuresList = this.$refs.workticketMeasureList
        ? this.$refs.workticketMeasureList.comGetSafetyMeasureData()
        : [];
      for (let i = 0; i < measuresList.length; i++) {
        const measures = measuresList[i].measures || [];
        for (let j = 0; j < measures.length; j++) {
          const item = measures[j];
          const params = item.safeMeasure?.params || [];
          if (item.checked && params.some(text => !text)) {
            return this.$toast("请填写变量控件中的内容");
          }
        }
      }

      // 跳转到选择人员的页面
      this.$refs.workticketMeasureList.sign(isContractor);
    },
    async validateSigned() {
      const r = await validateSigned(this.baseInfo.id);
      return r;
    },
    // 提交签名
    async submitSign() {
      // handlerConfirm回去校验是否已经全部签完
      this.handlerConfirm();
    },
    processDepartmentNames(data = []) {
      return data.slice(-2).join("-");
    },
    loadData() {
      getWorkticketBaseInfo(this.id).then(async baseInfo => {
        if (baseInfo.displayTerminalTime && baseInfo.terminalTime) {
          let t = Date.parse(baseInfo.terminalTime) - Date.now();
          baseInfo.terminalTime = t > 0 ? t : 0;
        }
        this.baseInfo = baseInfo;
        this.baseInfo.proposer = baseInfo.proposerList?.[0]?.id || "";
        this.baseInfo.supervisor = baseInfo.supervisorList?.[0]?.id || "";
        this.baseInfo.departmentNames = this.processDepartmentNames(
          baseInfo.departmentNames
        );
        this.status = baseInfo.status;
        this.specialTicketEditable = Array(
          (baseInfo.workTypeList || []).length
        ).fill(false);
        if (baseInfo.workJsaId) {
          this.workJsaInfo = await getWorkJsaInfo(baseInfo.workJsaId);
          if (this.isBy) {
            this.baseInfo.workTypeList = this.baseInfo.workTypeList.map(
              item => {
                const find = this.workJsaInfo.workTypeList.find(
                  i => i.workTypeId === item.id
                );
                if (find) {
                  item.workerList = find.workerList;
                }
                return item;
              }
            );
          }
        }
        // 默认展开所有
        let arr = this.baseInfo.workTypeList.map((v, i) => {
          return `${this.baseInfo.id}_workType_${i}`;
        });
        this.activeName = [
          "申请信息",
          "安全交底",
          "安全分析",
          "作业记录",
          "作业验收",
          ...arr
        ];
        if (this.isBy) {
          this.validateUpgradation();
          this.validateApprove();
        }
      });
      this.getPersonModifyRecords();
      this.getTicketEvaluate();
    },
    clickGasCheck() {
      this.$router.push(`/workticket/checkgas/${this.id}`);
    },
    dialogSuccessFn(api, params = {}, cb) {
      if (!api) {
        api = () =>
          new Promise(resolved => {
            resolved();
          });
      }
      return api(params)
        .then(res => {
          InputDialog.close();
          this.$toast.success("操作成功");
          cb && cb(res);
        })
        .catch(error => {
          InputDialog.removeLoading();
          this.$toast.fail(error.msg || "请求失败,请重试");
        });
    },
    InputDialogFun(
      title,
      api,
      apiParamsList = [],
      cb,
      hasCheckPerson,
      ticketId,
      department,
      hasInput = true,
      isRequired = true,
      isPersonByTab = false,
      choosePersonLabel,
      personByTabTitle,
      personByTabMultiple,
      showRecentUsePerson = false,
      recentType,
      maxlength,
      otherPerson,
      otherPersonLabel,
      isPowerCut,
      powerCutConfig = {},
      defaultCheckPerson = [],
      personType = "",
      t1 = "",
      t2 = "",
      excludePersonIds = [],
      pauseTime = null
    ) {
      const successCb = function() {
        const apiArg = Array.from(arguments).slice(1);
        api(...apiParamsList, ...arguments)
          .then(res => {
            InputDialog.close();
            this.$toast.success("操作成功");
            cb(res, arguments, ...apiArg);
          })
          .catch(error => {
            InputDialog.removeLoading();
            this.$toast.fail(error.msg || "请求失败,请重试");
          });
      };
      InputDialog.show({
        title,
        successCb,
        hasCheckPerson,
        ticketId,
        department,
        hasInput,
        isRequired,
        isPersonByTab,
        choosePersonLabel,
        personByTabTitle,
        personByTabMultiple,
        showRecentUsePerson,
        recentType,
        maxlength,
        otherPerson,
        otherPersonLabel,
        isPowerCut,
        powerCutConfig,
        defaultCheckPerson,
        personType,
        t1,
        t2,
        ticketEvaluate: this.ticketEvaluate,
        excludePersonIds,
        pauseTime
      });
    },
    InputDialogMultiplePersonFun(
      title,
      api,
      apiParamsList = [],
      cb,
      ticketId,
      config
      // hasCheckPerson,
      // department,
      // hasInput = true,
      // isRequired = true,
      // isPersonByTab = false,
      // choosePersonLabel,
      // personByTabTitle,
      // personByTabMultiple,
      // showRecentUsePerson = false,
      // recentType,
      // maxlength,
      // otherPerson,
      // otherPersonLabel,
      // isPowerCut,
      // powerCutConfig = {},
      // defaultCheckPerson = [],
      // personType = ""
    ) {
      const successCb = function() {
        const apiArg = Array.from(arguments).slice(1);
        api(...apiParamsList, ...arguments)
          .then(res => {
            InputDialogMultiplePerson.close();
            this.$toast.success("操作成功");
            cb(res, arguments, ...apiArg);
          })
          .catch(error => {
            InputDialogMultiplePerson.removeLoading();
            this.$toast.fail(error.msg || "请求失败,请重试");
          });
      };
      InputDialogMultiplePerson.show({
        title,
        successCb,
        ticketId,
        list: config
        // hasCheckPerson,
        // department,
        // hasInput,
        // isRequired,
        // isPersonByTab,
        // choosePersonLabel,
        // personByTabTitle,
        // personByTabMultiple,
        // showRecentUsePerson,
        // recentType,
        // maxlength,
        // otherPerson,
        // otherPersonLabel,
        // isPowerCut,
        // powerCutConfig,
        // defaultCheckPerson,
        // personType
      });
    },
    InputDialogComplexFun(
      title,
      api,
      apiParamsList = [],
      cb,
      hasCheckPerson,
      ticketId,
      department,
      hasInput = true,
      isRequired = true,
      isPersonByTab = false,
      choosePersonLabel,
      personByTabTitle,
      personByTabMultiple,
      showRecentUsePerson = false,
      recentType,
      maxlength,
      otherPerson,
      otherPersonLabel,
      isPowerCut,
      powerCutConfig = {},
      defaultCheckPerson = [],
      personType = "",
      dateRange = []
    ) {
      const successCb = function() {
        const apiArg = Array.from(arguments).slice(1);
        api(...apiParamsList, ...arguments)
          .then(res => {
            InputDialogComplex.close();
            this.$toast.success("操作成功");
            cb(res, arguments, ...apiArg);
          })
          .catch(error => {
            InputDialogComplex.removeLoading();
            this.$toast.fail(error.msg || "请求失败,请重试");
          });
      };
      InputDialogComplex.show({
        title,
        successCb,
        hasCheckPerson,
        ticketId,
        department,
        hasInput,
        isRequired,
        isPersonByTab,
        choosePersonLabel,
        personByTabTitle,
        personByTabMultiple,
        showRecentUsePerson,
        recentType,
        maxlength,
        otherPerson,
        otherPersonLabel,
        isPowerCut,
        powerCutConfig,
        defaultCheckPerson,
        personType,
        dateRange
      });
    },
    async handlerOther() {
      const { status } = this;
      switch (status) {
        // 取消开票审批
        case WORKTICKET_STATUS.Draft:
          this.$dialog.confirm({ message: "确认取消开票审批？" }).then(() => {
            appWorkStartApprove(this.id, -1)
              .then(res => {
                this.$toast.success("操作成功");
                this.$router.back();
              })
              .catch(error => {
                this.$toast.fail("请求失败,请重试");
              });
          });
          break;

        // 暂停
        case WORKTICKET_STATUS.Working:
          this.InputDialogFun("暂停原因", pauseWork, [this.id], res => {
            if (this.$refs.WorkticketAttachmentView) {
              this.$refs.WorkticketAttachmentView.getData();
            }
            this.$toast.success("操作成功");
            this.status = WORKTICKET_STATUS.Paused;
          });
          break;

        // 继续
        case WORKTICKET_STATUS.Paused:
          const hight = this.baseInfo.workTypeList.find(
            i => i.specialJobType === "3"
          );
          if (hight) {
            const pauseTime = await getPauseTime({ ticketId: this.id }); // 单位：小时
            // 作业暂停小于1h，不用再次确认
            if (pauseTime !== "0") {
              let signatureList = [];
              let lastNodeName = "";
              let guarderList = [];
              if (pauseTime > 2) {
                // 暂停3h以上，需要审批流最后一个节点的人员审批
                const workNodes = await getWorkNodes(hight.specialId);
                const lastNode = maxBy(workNodes, "type");
                lastNodeName = lastNode.name;
                signatureList = deepClone(lastNode.approvers || []).map(
                  item => ({
                    ...item,
                    specialId: hight.specialId
                  })
                );
              } else {
                // 作业暂停1-3h，需要监护人签字
                const specialData = await getWorkticketTypeDetail(
                  "3",
                  hight.specialId
                );
                guarderList = specialData.guarderList.map(i => {
                  return {
                    ...i,
                    personId: i.workerId,
                    personName: i.workerName,
                    personType: i.workerType,
                    specialId: i.specialWorkId
                  };
                });
              }
              this.InputDialogFun(
                "确认继续作业？",
                (res, arg) =>
                  new Promise(rlv => {
                    rlv(res, arg);
                  }),
                [],
                (res, arg, apiArg) => {
                  const params = {
                    supplementMeasures: res,
                    signatureList: signatureList.filter(i => i.fileId)
                  };
                  continueHighWork(this.id, params)
                    .then(res => {
                      if (this.$refs.WorkticketAttachmentView) {
                        this.$refs.WorkticketAttachmentView.getData();
                      }
                      this.$toast.success("操作成功");
                      this.status = WORKTICKET_STATUS.Working;
                    })
                    .catch(error => {
                      if (error && error.code !== "30006") {
                        this.$toast.fail("请求失败,请重试");
                      }
                    });
                },
                false,
                this.id,
                this.baseInfo.department,
                true,
                true,
                false,
                "",
                "",
                false,
                false,
                "",
                200,
                pauseTime > 2 ? signatureList : guarderList,
                pauseTime > 2 ? lastNodeName : "监护",
                false,
                {},
                [],
                "2",
                "环境条件和安全措施确认",
                "变化及补充措施",
                [],
                pauseTime
              );
              break;
            }
          }
          continueWork(this.id)
            .then(res => {
              if (this.$refs.WorkticketAttachmentView) {
                this.$refs.WorkticketAttachmentView.getData();
              }
              this.$toast.success("操作成功");
              this.status = WORKTICKET_STATUS.Working;
            })
            .catch(error => {
              if (error && error.code !== "30006") {
                this.$toast.fail("请求失败,请重试");
              }
            });
          break;
      }
    },
    async handlerCancel() {
      const { status } = this;
      switch (status) {
        case WORKTICKET_STATUS.Draft:
          console.log("handlerCancel--待开票审批");
          this.InputDialogFun(
            "退回理由",
            appWorkStartApprove,
            [this.id, 0],
            res => {
              // this.loadData();
              this.$router.replace({
                name: "workticketAdd",
                params: {
                  id: this.id
                }
              });
            }
          );
          break;

        case WORKTICKET_STATUS.Working:
        case WORKTICKET_STATUS.Paused:
          console.log("handlerCancel--作业种、暂停");
          const workRecord = await this.getSpaceWorkRecord();
          if (workRecord && !workRecord.toolRecordList.length) {
            return this.$toast.fail("未填写工器具进出记录");
          }
          this.InputDialogFun(
            "终止原因",
            stopWorking,
            [this.id, this.baseInfo.realStart],
            res => {
              this.loadData();
              // this.status = WORKTICKET_STATUS.Delivering;
            }
          );
          break;

        case WORKTICKET_STATUS.Delivering:
        case WORKTICKET_STATUS.Unqualified:
          console.log("handlerCancel--待验收");
          // this.InputDialogFun(
          //   "验收不合格原因",
          //   finishWorkingDeliver,
          //   [this.id, 0],
          //   res => {
          //     this.status = WORKTICKET_STATUS.Unqualified;
          //   },
          //   true,
          //   this.id,
          //   this.baseInfo.department,
          //   true,
          //   true,
          //   true,
          //   "验收",
          //   "验收人(多选)",
          //   true
          // );
          this.InputDialogFun(
            "验收不合格原因",
            () =>
              new Promise(rlv => {
                rlv();
              }),
            [],
            (res, arg) => {
              const personInfo = (arg[1] || []).map(i => {
                return {
                  id: i.value,
                  type: i._mechanismType
                };
              });
              finishWorkingDeliver(this.id, 0, arg[0], arg[1], personInfo)
                .then(r => {
                  this.$toast.success("操作成功");
                  this.status = WORKTICKET_STATUS.Unqualified;
                })
                .catch(error => {
                  this.$toast.fail("请求失败,请重试");
                });
            },
            true,
            this.id,
            this.baseInfo.department,
            true,
            true,
            true,
            "验收",
            "验收人(多选)",
            true,
            true,
            "8"
          );
          break;

        case WORKTICKET_STATUS.Safety_disclosure:
        case WORKTICKET_STATUS.Approving:
          const data = await getWorkSafeWork(this.id);
          let work = data.find(i => i.personType === "1")?.workerList || [];
          work = uniqBy(
            work.reduce((acc, cur) => {
              acc.push(...(cur?.workerList || []));
              return acc;
            }, []),
            "personId"
          ).filter(item => Number(item.current) === 1);
          work = work.map(i => {
            return {
              type: "0", // 如果需要改变type则需要从上面接口数据里获取
              fileId: "",
              personType: "301",
              personId: i.personId,
              personName: i.personName,
              id: i.personId,
              name: i.personName,
              ticketId: this.id,
              specialWorkId: i.specialId
            };
          });
          this.InputDialogComplexFun(
            "取消原因",
            (res, arg) =>
              new Promise(rlv => {
                rlv(res, arg);
              }),
            [],
            (res, arg, apiArg) => {
              const personInfo = (res?.choosePerson || []).map(i => {
                return {
                  ticketId: this.id,
                  id: i.value,
                  name: i.name,
                  type: i._mechanismType,
                  personId: i.value,
                  personType: "302",
                  fileId: i.fileId
                };
              });
              cancelWorkticket(this.id, {
                remark: res.remark,
                status: this.status,
                workerList: [...personInfo, ...work]
              })
                .then(res => {
                  this.$toast.success("操作成功");
                  this.reload();
                })
                .catch(error => {
                  this.$toast.fail("请求失败,请重试");
                });
            },
            true,
            this.id,
            this.baseInfo.department,
            true,
            true,
            true,
            "批准",
            "批准人(单选)",
            false,
            false,
            "",
            500,
            work.length ? work : [],
            "作业负责",
            false,
            {},
            [],
            "302",
            []
          );
          break;
        case WORKTICKET_STATUS.Safety_analysis:
        case WORKTICKET_STATUS.Analysing:
        case WORKTICKET_STATUS.Gas_monitor:
          this.InputDialogFun(
            "取消原因",
            (res, arg) =>
              new Promise(rlv => {
                rlv(res, arg);
              }),
            [],
            (res, arg, apiArg) => {
              cancelWorkticket(this.id, {
                remark: res,
                status: this.status
              })
                .then(res => {
                  this.$toast.success("操作成功");
                  this.reload();
                })
                .catch(error => {
                  this.$toast.fail("请求失败,请重试");
                });
            }
          );
          break;
      }
    },
    async handlerConfirm() {
      let { status } = this;

      if (WORKTICKET_STATUS.Draft == status) {
        console.log("handlerConfirm--待开票审批");
        //待开票审批
        this.$dialog.confirm({ message: "确认通过开票审批？" }).then(() => {
          appWorkStartApprove(this.id, 1)
            .then(res => {
              this.loadData();
              this.$toast.success("操作成功");
              // this.status = WORKTICKET_STATUS.Safety_analysis;
            })
            .catch(error => {
              this.$toast.fail("请求失败,请重试");
            });
        });
      } else if (
        [
          WORKTICKET_STATUS.Safety_analysis,
          WORKTICKET_STATUS.Craft_safety_analysis,
          WORKTICKET_STATUS.Overhaul_safety_analysis
        ].includes(status)
      ) {
        console.log("handlerCancel--待安全分析");
        const apiMap = {
          [WORKTICKET_STATUS.Safety_analysis]: updateGoSafetyAnalysis,
          [WORKTICKET_STATUS.Craft_safety_analysis]: startCraftOverhaulAnalyse,
          [WORKTICKET_STATUS.Overhaul_safety_analysis]: startCraftOverhaulAnalyse
        };
        const api = apiMap[status];
        this.$dialog.confirm({ message: "确认开始安全分析？" }).then(() => {
          api(this.id)
            .then(res => {
              this.loadData();
              this.$toast.success("操作成功");
              // this.status = WORKTICKET_STATUS.Analysing;
            })
            .catch(error => {
              this.$toast.fail("请求失败,请重试");
            });
        });
      } else if (
        [
          WORKTICKET_STATUS.Analysing,
          WORKTICKET_STATUS.Craft_analysing,
          WORKTICKET_STATUS.Overhaul_analysing
        ].includes(status)
      ) {
        console.log("handlerCancel--安全分析中");
        const apiMap = {
          [WORKTICKET_STATUS.Analysing]: finishSafetyAnalysis,
          [WORKTICKET_STATUS.Craft_analysing]: finishCraftOverhaulAnalyse,
          [WORKTICKET_STATUS.Overhaul_analysing]: finishCraftOverhaulAnalyse
        };
        const api = apiMap[status];
        // if (!this.$refs.WorkticketSafeAnalysisView.checkReady()) {
        //   //安全分析未完成
        //   this.$toast.fail("安全分析未完成");
        //   return;
        // }

        //先判断编辑是否都完成
        validateWorkWork(this.id)
          .then(res => {
            console.log("validateWorkWork--", res);
            if (res) {
              //不通过
              this.$toast.fail(res.name + "未完成安全分析");
              return;
            }
            this.$dialog.confirm({ message: "确认完成分析？" }).then(() => {
              api(this.id)
                .then(res => {
                  this.reload();
                  this.$toast.success("操作成功");
                  // this.status = this.hasGasCheckWork
                  //   ? WORKTICKET_STATUS.Gas_monitor
                  //   : WORKTICKET_STATUS.Safety_disclosure;
                  //刷新当前页面，同步检测人状态
                })
                .catch(error => {
                  this.$toast.fail("请求失败,请重试");
                });
            });
          })
          .catch(error => {
            this.$toast.fail("请求失败,请重试");
          });
      } else if (WORKTICKET_STATUS.Gas_monitor == status) {
        console.log("handlerCancel--待气体检测");
        this.$dialog.confirm({ message: "确认完成气体检测？" }).then(() => {
          finishGasMonitor(this.id)
            .then(res => {
              this.$toast.success("操作成功");
              this.status = WORKTICKET_STATUS.Safety_disclosure;
            })
            .catch(error => {
              this.$toast.fail(error.msg || "请求失败,请重试");
            });
        });
      } else if (
        [
          WORKTICKET_STATUS.Safety_disclosure,
          WORKTICKET_STATUS.Craft_safety_disclosure,
          WORKTICKET_STATUS.Overhaul_safety_disclosure
        ].includes(status)
      ) {
        console.log("handlerCancel--带安全交底");
        // 跳转到签字页面
        // 如果已经都签完了字, 则直接跳转到提交页面
        // 需求更改, 每次点击都需要经过提交页面
        // const res = await this.validateSigned();
        // if (res) {
        //   this.status = WORKTICKET_STATUS.Safety_disclosureing;
        //   return;
        // }
        this.$dialog
          .confirm({
            confirmButtonText: "是",
            cancelButtonText: "否",
            message: "现场人员与计划人员一致，开始安全交底？"
          })
          .then(() => {
            this.status = WORKTICKET_STATUS.Safety_disclosure_sign;
          });
      } else if (WORKTICKET_STATUS.Safety_disclosure_sign == status) {
        const pictureData = this.$refs.workticketMeasureList.getPictureData();
        if (pictureData) {
          if (!pictureData.some(i => i.pictureFileIdList.length)) {
            return this.$toast("所有措施至少上传一张图片");
          }
        }
        const res = await this.validateSigned();
        if (!res) {
          return this.$toast("请全部签完字之后提交");
        }
        const params = {
          ticketId: this.baseInfo.id
        };
        if (this.projectName === "RT") {
          const measuresList = this.$refs.workticketMeasureList
            ? this.$refs.workticketMeasureList.comGetSafetyMeasureData()
            : [];
          const personIds = new Set();
          measuresList.forEach(item => {
            const measures = item.measures || [];
            measures.forEach(i => {
              personIds.add(i.operator);
            });
          });
          this.InputDialogFun(
            "本栏所有安全措施已落实到位：",
            (res, arg) =>
              new Promise(rlv => {
                rlv(res, arg);
              }),
            [],
            async (res, arg, apiArg) => {
              const p = apiArg?.[0] || {};
              params.personType = "3301";
              params.personId = p.value;
              params.orgType = p._mechanismType;
              params.fileId = p.fileId;
              await saveSafetyMeasureFile(pictureData);
              // 记录提交流程
              safetyMeasureSubmit(params).then(res => {
                if (res) {
                  // 如果已经都签完了字, 则直接跳转到提交页面
                  this.status = WORKTICKET_STATUS.Safety_disclosureing;
                } else {
                  this.$toast("确认失败, 请重试");
                }
              });
            },
            true,
            this.id,
            this.baseInfo.department,
            false,
            true,
            true,
            "作业地点负责",
            "作业地点负责人(单选)",
            false,
            false,
            "3301",
            500,
            [],
            "作业地点负责人",
            false,
            {},
            [],
            "3301",
            "",
            "",
            [...personIds]
          );
        } else {
          await saveSafetyMeasureFile(pictureData);
          // 记录提交流程
          // const SMData = this.$refs.workticketMeasureList.comGetSafetyMeasureData();
          safetyMeasureSubmit(params).then(res => {
            if (res) {
              // 如果已经都签完了字, 则直接跳转到提交页面
              this.status = WORKTICKET_STATUS.Safety_disclosureing;
            } else {
              this.$toast("确认失败, 请重试");
            }
          });
        }
      } else if (WORKTICKET_STATUS.Safety_disclosureing == status) {
        console.log("handlerCancel--安全交底中");
        let api = appWorkSafekDisclosure;
        if (
          this.isBy &&
          this.baseInfo.workTypeList[0]?.specialJobType ===
            WORKTICKET_TYPE["equipment-safety"]
        ) {
          api = finishCraftOverhaulDisclosure;
        }
        this.$dialog.confirm({ message: "确认提交？" }).then(() => {
          let files = this.$refs.WorkticketSafedDsclosureView.getFiles();
          api(this.id, files)
            .then(res => {
              this.$toast.success("操作成功");
              switch (this.baseInfo.status) {
                case WORKTICKET_STATUS.Craft_safety_disclosure:
                  this.status = WORKTICKET_STATUS.Overhaul_safety_analysis;
                  break;
                default:
                  this.status = WORKTICKET_STATUS.Approving;
              }
            })
            .catch(error => {
              this.$toast.fail("请求失败,请重试");
            });
        });
      }
      // else if (WORKTICKET_STATUS.Approving == status) {
      //   console.log("handlerCancel--审批中");
      //   finishApproving(this.id)
      //     .then(res => {
      //       this.$toast.success("操作成功");
      //       this.status = WORKTICKET_STATUS.PendingJob;
      //     })
      //     .catch(error => {
      //       this.$toast.fail("请求失败,请重试");
      //     });
      // }
      else if (WORKTICKET_STATUS.PendingJob == status) {
        console.log("handlerCancel--待作业");
        const fire = this.baseInfo.workTypeList.find(
          i => i.specialJobType === "1"
        );
        const space = this.baseInfo.workTypeList.find(
          i => i.specialJobType === "2"
        );
        const conf = [];
        if (fire) {
          conf.push({
            hasCheckPerson: true,
            department: this.baseInfo.department,
            hasInput: false,
            isRequired: true,
            isPersonByTab: true,
            choosePersonLabel: "验票",
            personByTabTitle: "验票人(单选)",
            personByTabMultiple: false,
            showRecentUsePerson: false,
            recentType: "",
            maxlength: 500,
            otherPerson: [],
            otherPersonLabel: "",
            isPowerCut: false,
            powerCutConfig: {},
            defaultCheckPerson: [],
            personType: "25",
            specialId: fire.specialId,
            specialJobType: fire.specialJobType,
            startWorkSpaceWorker: false
          });
          // this.InputDialogFun(
          //   "确认开始作业？",
          //   (res, arg) =>
          //     new Promise(rlv => {
          //       rlv(res, arg);
          //     }),
          //   [],
          //   (res, arg, apiArg) => {
          //     const personInfo = (apiArg || []).map(i => {
          //       return {
          //         ...i,
          //         type: i._mechanismType,
          //         personType: "25",
          //         specialWorkId: fire.specialId
          //       };
          //     });
          //     startWorking(this.id, {
          //       workerList: personInfo
          //     })
          //       .then(res => {
          //         this.loadData();
          //         this.$toast.success("操作成功");
          //       })
          //       .catch(error => {
          //         // 气体检测限制超时
          //         if (error.code !== "30006") {
          //           this.$toast.fail("请求失败,请重试");
          //         }
          //       });
          //   },
          //   true,
          //   this.id,
          //   this.baseInfo.department,
          //   false,
          //   true,
          //   true,
          //   "验票",
          //   "验票人(单选)",
          //   false,
          //   false,
          //   "",
          //   500,
          //   [],
          //   "",
          //   false,
          //   {},
          //   [],
          //   "25"
          // );
          // return;
        }
        if (space) {
          conf.push({
            hasCheckPerson: true,
            department: this.baseInfo.department,
            hasInput: false,
            isRequired: true,
            isPersonByTab: false,
            choosePersonLabel: "进入",
            personByTabTitle: "",
            personByTabMultiple: false,
            showRecentUsePerson: false,
            recentType: "",
            maxlength: 500,
            otherPerson: [],
            otherPersonLabel: "",
            isPowerCut: false,
            powerCutConfig: {},
            defaultCheckPerson: [],
            personType: "",
            specialId: space.specialId,
            specialJobType: space.specialJobType,
            startWorkSpaceWorker: true
          });
        }

        if (fire || space) {
          this.InputDialogMultiplePersonFun(
            "确认开始作业？",
            (res, arg) =>
              new Promise(rlv => {
                rlv(res, arg);
              }),
            [],
            async (res, arg, apiArg) => {
              const personInfo = apiArg
                .filter(i => i.specialJobType === "1")
                .map(i => {
                  return {
                    ...i,
                    type: i._mechanismType,
                    personType: "25",
                    specialWorkId: fire.specialId
                  };
                });
              startWorking(this.id, {
                workerList: personInfo
              })
                .then(res => {
                  this.loadData();
                  this.$toast.success("操作成功");
                  if (space) {
                    const personRecord = apiArg
                      .filter(i => i.specialJobType === "2")
                      .map(i => {
                        return {
                          fileId: i.fileId,
                          workerId: i.value,
                          workerType: i.personType
                        };
                      });
                    const params = {
                      ticketId: this.id,
                      specialTicketId: space.specialId,
                      recordType: 0,
                      enterType: 0,
                      detailRecordList: personRecord
                    };
                    addSpaceWorkRecord(params);
                  }
                })
                .catch(error => {
                  // 气体检测限制超时
                  if (error.code !== "30006") {
                    this.$toast.fail("请求失败,请重试");
                  }
                });
            },
            this.id,
            conf
          );
          return;
        }
        this.$dialog.confirm({ message: "确认开始作业？" }).then(() => {
          startWorking(this.id, {
            workerList: []
          })
            .then(res => {
              this.loadData();
              this.$toast.success("操作成功");
              // this.status = WORKTICKET_STATUS.Working;
            })
            .catch(error => {
              // 气体检测限制超时
              if (error.code !== "30006") {
                this.$toast.fail("请求失败,请重试");
              }
            });
        });
      } else if (
        WORKTICKET_STATUS.Working == status ||
        WORKTICKET_STATUS.Paused == status
      ) {
        console.log("handlerCancel--作业中");
        const space = this.baseInfo.workTypeList.find(
          i => i.specialJobType === "2"
        );
        if (space) {
          const workRecord = await this.getSpaceWorkRecord();
          if (workRecord && !workRecord.toolRecordList.length) {
            return this.$toast.fail("未填写工器具进出记录");
          }
          const spaceAllExit = await getSpaceWorkRecordExit({
            ticketId: this.id,
            specialTicketId: space.specialId
          });
          if (!spaceAllExit) {
            return this.$toast.fail("人员及工器具未全部退出");
          }
        }
        const data = await getWorkSafeWork(this.id);
        let work = data.find(i => i.personType === "10")?.workerList || [];
        work = uniqBy(
          work.reduce((acc, cur) => {
            acc.push(...(cur?.workerList || []));
            return acc;
          }, []),
          "personId"
        );
        work = work.map(i => {
          return {
            type: "0",
            _mechanismType: i.personType,
            fileId: "",
            personType: "26",
            personId: i.personId,
            personName: i.personName,
            id: i.personId,
            name: i.personName,
            label: i.personName,
            value: i.personId,
            ticketId: this.id,
            specialWorkId: i.specialId,
            specialId: i.specialId
          };
        });
        const electricity = this.baseInfo.workTypeList.find(
          i => i.specialJobType === "8"
        );
        if (work.length || electricity) {
          this.InputDialogFun(
            "确认完成作业？",
            (res, arg) =>
              new Promise(rlv => {
                rlv(res, arg);
              }),
            [],
            (res, arg, apiArg) => {
              const personInfo = (apiArg || []).map(i => {
                return {
                  ...i,
                  type: i._mechanismType || i.type || "0",
                  personType: "26",
                  specialWorkId: i.specialId
                };
              });
              finishWorking(this.id, {
                workerList: personInfo
              })
                .then(res => {
                  this.loadData();
                  if (this.$refs.WorkticketAttachmentView) {
                    this.$refs.WorkticketAttachmentView.getData();
                  }
                  this.$toast.success("操作成功");
                })
                .catch(error => {
                  this.$toast.fail("请求失败,请重试");
                });
            },
            true,
            this.id,
            this.baseInfo.department,
            false,
            true,
            true,
            "停电",
            "停电人(单选)",
            false,
            false,
            "",
            500,
            [],
            "",
            true,
            {
              licenseTypes: this.licenseTypesSwitch(26, 8)
            },
            [] // 人员默认值：此前传work，后改为不展示
          );
          return;
        }
        if (
          this.isBy &&
          this.baseInfo.workTypeList[0]?.specialJobType ===
            WORKTICKET_TYPE["equipment-safety"]
        ) {
          this.$refs.dialogEquipmentSafetyAccomplish.open({
            ticketId: this.id
          });
          return;
        }
        this.$dialog.confirm({ message: "确认完成作业？" }).then(() => {
          finishWorking(this.id, {
            workerList: []
          })
            .then(res => {
              this.loadData();
              if (this.$refs.WorkticketAttachmentView) {
                this.$refs.WorkticketAttachmentView.getData();
              }
              this.$toast.success("操作成功");
              // this.status = WORKTICKET_STATUS.Delivering;
            })
            .catch(error => {
              this.$toast.fail("请求失败,请重试");
            });
        });
      } else if (
        WORKTICKET_STATUS.Delivering == status ||
        WORKTICKET_STATUS.Unqualified == status
      ) {
        console.log("handlerCancel--待验收");
        // this.InputDialogFun(
        //   "确认验收合格？",
        //   finishWorkingDeliver,
        //   [this.id, 1],
        //   res => {
        //     this.$toast.success("操作成功");
        //     this.status = WORKTICKET_STATUS.Qualified;
        //   },
        //   true,
        //   this.id,
        //   this.baseInfo.department,
        //   false,
        //   true,
        //   true,
        //   "验收",
        //   "验收人(多选)",
        //   true
        // );
        this.InputDialogFun(
          "确认验收合格？",
          () =>
            new Promise(rlv => {
              rlv();
            }),
          [],
          (res, arg) => {
            const personInfo = (arg[1] || []).map(i => {
              return {
                id: i.value,
                type: i._mechanismType
              };
            });
            finishWorkingDeliver(this.id, 1, arg[0], arg[1], personInfo)
              .then(r => {
                this.$toast.success("操作成功");
                if (!this.baseInfo.closeFlag) {
                  this.reload(); // 刷新页面到“已关闭”
                  return;
                }
                this.status = WORKTICKET_STATUS.Qualified;
                this.getTicketEvaluate();
              })
              .catch(error => {
                this.$toast.fail("请求失败,请重试");
              });
          },
          true,
          this.id,
          this.baseInfo.department,
          false,
          true,
          true,
          "验收",
          "验收人(多选)",
          true,
          true,
          "8"
        );
      } else if (WORKTICKET_STATUS.Qualified == status) {
        if (this.projectName === "RT") {
          const res = await checkWorkticket(this.id);
          if (res === false) {
            this.$dialog
              .confirm({
                title: "",
                message: "作业未完成监督检查，请完成检查后关闭。",
                confirmButtonText: "去检查",
                cancelButtonText: "确认"
              })
              .then(() => {
                this.onGoInspect("inspect");
              })
              .catch(() => {});
            return;
          }
        }
        const data = await getWorkSafeWork(this.id);
        let work = data.find(i => i.personType === "1")?.workerList || [];
        work = uniqBy(
          work.reduce((acc, cur) => {
            acc.push(...(cur?.workerList || []).filter(i => i.current));
            return acc;
          }, []),
          "personId"
        );
        work = work.map(i => {
          return {
            type: i.personType,
            fileId: "",
            personType: "24",
            personId: i.personId,
            personName: i.personName,
            id: i.personId,
            name: i.personName,
            ticketId: this.id,
            specialWorkId: i.specialId
          };
        });
        // 默认选中登录用户
        const defaultList = [
          {
            type: "0",
            _mechanismType: "14",
            fileId: "",
            personType: "14",
            personId: this.userInfo.id,
            personName: this.userInfo.nickName,
            id: this.userInfo.id,
            name: this.userInfo.nickName,
            label: this.userInfo.nickName,
            value: this.userInfo.id,
            ticketId: this.id
            // specialWorkId: i.specialId,
            // specialId: i.specialId
          }
        ];
        this.InputDialogFun(
          "确认关闭作业？",
          (res, arg) =>
            new Promise(rlv => {
              rlv(res, arg);
            }),
          [],
          (res, arg, apiArg) => {
            const personInfo = (apiArg || []).map(i => {
              return {
                id: i.value,
                type: i._mechanismType
              };
            });
            closeWork(this.id, {
              closerList: apiArg,
              closerListInfo: personInfo,
              closerLeaderList: this.projectName === "RT" ? work : []
            })
              .then(res => {
                this.$toast.success("操作成功");
                this.status = WORKTICKET_STATUS.Close;
              })
              .catch(error => {
                this.$toast.fail("请求失败,请重试");
              });
          },
          true,
          this.id,
          this.baseInfo.department,
          false,
          true,
          true,
          "关闭",
          "关闭人(单选)",
          false,
          true,
          "9",
          500,
          this.projectName === "RT" ? work : [],
          "作业负责",
          false,
          {},
          defaultList
        );
      }
    },
    //安全分析中：判断带分析项是否都完成了，安全分析item和特殊作业item
    checkEditItemAlready() {},
    workTicketRouterLink(id) {
      this.$router.push({
        name: "worktickeDetail",
        params: { id }
      });
    },
    workJsaRouterJump() {
      this.$router.push({
        name: "workBeforeSafetyAnalyzeEdit",
        params: {
          type: "detail",
          id: this.baseInfo.workJsaId
        }
      });
    },
    licenseTypesSwitch(workerType, jobType) {
      const data = this.baseInfo.workTypeList.find(
        i => Number(i.specialJobType) === Number(jobType)
      );
      if (!data.workTypeLicenseList) return "";
      const find = (data.workTypeLicenseList || []).find(t => {
        return Number(t.workerType) === Number(workerType);
      });
      if (find) {
        return find.licenseTypeList
          .map(i => {
            const t = i.replace(/,/g, "-");
            return t;
          })
          .toString();
      }
      return "";
    },
    async getSpaceWorkRecord() {
      const space = this.baseInfo.workTypeList.find(
        i => i.specialJobType === "2"
      );
      if (space) {
        return await getSpaceWorkRecord({
          ticketId: this.id,
          specialTicketId: space.specialId
        });
      }
      return null;
    },
    previewSign(fileId) {
      if (!fileId) return;
      esign.showImage(`${fileUrl}${fileId}`);
    },
    tagTypeSwitch(item) {
      if (item.fileId) {
        return item.involved ? "purple" : "green";
      }
      return "white";
    },
    ticketRecall() {
      this.$dialog.confirm({ message: "确认撤回开票审批？" }).then(() => {
        ticketRecall(this.id)
          .then(res => {
            this.$toast.success("操作成功");
            this.$router.back();
          })
          .catch(error => {
            this.$toast.fail("请求失败,请重试");
          });
      });
    },
    async validateUpgradation() {
      const { id, department, workTypeList } = this.baseInfo;
      const valid = await validateWorkUpgradeStatus(id);
      const fireLevel = workTypeList?.[0]?.fireLevel;
      if (valid) {
        this.$refs.dialogUpgradation.open({
          ticketId: id,
          department,
          fireLevel
        });
      }
    },
    async validateApprove() {
      const valid = await getWorkUpgradeApproveStatus(this.baseInfo.id);
      if (valid) {
        this.$refs.dialogApprove.open({ ticketId: this.baseInfo.id });
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.safe-disclosure-sign {
  padding: 0 0 60px;
}
.view-workticket-detail {
  .top-box {
    padding: 16px 16px 6px;
    &.top-box-info {
      min-height: 44px;
      .countdown {
        margin-top: 10px;
        // position: absolute;
        // right: 32px;
        // top: 45%;
        text-align: right;
        .label {
          font-size: 12px;
          font-weight: 400;
          color: #8c8f97;
          margin: 0 0 2px;
          // text-align: left;
          display: flex;
          justify-content: space-between;
          .warning-button {
            width: 64px;
            height: 24px;
            border-radius: 4px;
            border: 1px solid #e1e3e8;
            display: inline-block;
            text-align: center;
            line-height: 24px;
          }
        }
        .colon {
          display: inline-block;
          margin: 0 2px;
          font-size: 12px;
          font-weight: 400;
          color: #8c8f97;
        }
        .time-text {
          margin-right: 10px;
        }
        .block {
          display: inline-block;
          min-width: 16px;
          height: 16px;
          line-height: 16px;
          padding: 0 2px;
          box-sizing: border-box;
          border-radius: 2px;
          color: #fff;
          font-size: 12px;
          font-weight: 600;
          text-align: center;
          background-color: #ffae13;
        }
        .block.time-up {
          background-color: #fa5555;
        }
      }
    }
    .title {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: $--color-black-title;
      line-height: 22px;
      margin-bottom: 5px;
    }

    .desc {
      font-size: 12px;
      text-align: center;
      color: $--color-gray-item-text;
      line-height: 17px;
    }
  }
  .work-type-box {
    padding: 10px 8px 10px 12px;
    margin: 0 0 8px;
    background-color: #f5f7fa;
    border-radius: 6px;
    .left-box {
      word-break: break-all;
      font-size: 14px;
      color: #8c8f97;
      line-height: 20px;
      .type-name {
        color: #8c8f97;
        margin: 0 0 6px;
      }
      p:nth-child(2) {
        margin: 0 0 2px;
      }
    }
  }
  .work-link-box {
    padding: 4px 16px 0;
    .item-box {
      display: flex;
      margin: 0 0 8px;
      div {
        flex: 1;
      }
      .left-box {
        flex: initial;
        padding: 2px 4px 0 0;
      }
      .content {
        flex: initial;
        width: 295px;
        font-size: 14px;
        font-weight: 400;
        color: #1676ff;
        line-height: 20px;
      }
    }
    // .close-box {
    //   display: inline-block;
    //   width: 13px;
    //   height: 13px;
    //   background-color: #aeb3c0;
    //   color: #efefef;
    //   font-size: 14px;
    //   text-align: center;
    //   line-height: 13px;
    //   border-radius: 13px;
    //   margin: 2px 0 0 14px;
    // }
  }
  .measures-group {
    .measures-title {
      height: 48px;
      line-height: 48px;
      font-size: 22px;
      padding: 0 16px;
      font-weight: 700;
    }
    .measures-table {
      width: 100%;
      .measure-tr {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
        height: 54px;
        padding: 0 16px;
        box-sizing: border-box;
        overflow: hidden;
        &:not(:last-child) {
          &::after {
            position: absolute;
            content: "";
            bottom: 0;
            left: 16px;
            right: 16px;
            height: 1px;
            background-color: #eceef2;
            transform: scaleY(0.5);
          }
        }
        &.first {
          height: 32px;
          background-color: #eceef2;
        }
        .measure-th,
        .measure-td {
          color: #8c8f97;
          font-size: 14px;
          .van-checkbox {
            ::v-deep {
              .van-icon {
                background-color: #fff;
              }
              .van-checkbox__icon--checked {
                .van-icon {
                  background-color: $--color-primary;
                }
              }
              .van-checkbox__label {
                color: #8c8f97;
              }
            }
          }
          &:nth-child(1) {
            width: 80px;
          }
          &:nth-child(2) {
            flex: 1;
          }
          &:last-child {
            width: 100px;
          }
        }
        .measure-td {
          color: #2e2e4d;
          .van-checkbox {
            ::v-deep {
              .van-checkbox__icon--checked {
                .van-icon {
                  background-color: $--color-primary;
                }
              }
              .van-checkbox__label {
                color: #2e2e4d;
              }
            }
          }
        }
      }
    }
  }
  .approve-action-sheet {
    width: calc(100% - 16px);
    margin: 8px;
    background-color: transparent;
    border-radius: 14px;
    .van-action-sheet__description {
      background: #fff;
      color: #2e2e4d;
      border-bottom: 1px solid #eceef2;
      font-size: 16px;
      padding: 16px;
      &::after {
        border-bottom: none;
      }
    }
    .van-action-sheet__content {
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;
      .van-action-sheet__item {
        font-size: 18px;
        font-weight: 500;
        line-height: 23px;
      }
      .approve-item {
        border-bottom: 1px solid #eceef2;
      }
      .pass {
        color: #1676ff;
      }
      .reject {
        color: #f13030;
      }
    }
    .van-action-sheet__gap {
      background-color: transparent;
    }
    .van-action-sheet__cancel {
      border-radius: 14px;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      color: #6e6e7d;
    }
  }
  .safe-btn {
    // width: 104px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-weight: 400;
    padding: 6px 10px;
  }
  .person-change-record-box {
    padding: 0 16px 0;
    .person-change-record {
      border-bottom: 1px solid #e1e3e8;
      .title {
        font-size: 14px;
        font-weight: 500;
        color: #2e2e4d;
        line-height: 21px;
        margin: 0 0 9px;
      }
      .row {
        display: flex;
        font-size: 14px;
        font-weight: 400;
        color: #3b4664;
        line-height: 30px;
        .label {
          flex-shrink: 0;
          width: 106px;
        }
        .name-icon-box {
          display: inline-block;
          margin: 0 12px 0 0;
          .qianming {
            margin: 0 0 0 4px;
            color: #2eb752;
            cursor: pointer;
          }
        }
      }
      .date {
        font-size: 12px;
        font-weight: 400;
        color: #8c8f97;
        line-height: 17px;
        margin: 8px 0 12px;
        text-align: right;
      }
    }
    .person-change-record:not(:first-child) {
      padding: 12px 0 0;
    }
  }
  .evaluate-result {
    .van-cell__value {
      color: #2e2e4d;
    }
  }
}
</style>
<style lang="scss">
.view-workticket-detail {
  .van-collapse-item {
    position: relative;
    &:before {
      height: 48px;
      content: "";
      display: block;
    }
    &.edit-button-editing {
      z-index: 2;
      .van-collapse-item__title {
        //position: fixed;
      }
    }
    .van-collapse-item__title {
      position: absolute;
      z-index: 2;
      top: 0;
    }
  }
}
</style>
