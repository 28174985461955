var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('van-popup',_vm._g(_vm._b({staticClass:"dialog-approve-all",attrs:{"close-on-click-overlay":false,"position":"bottom","overlay":false,"get-container":"body"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},'van-popup',_vm.$attrs,false),_vm.$listeners),[_c('div',{staticClass:"header"},[_c('h3',{staticClass:"title"},[_vm._v("确认卡")]),_c('van-icon',{staticClass:"close-icon",attrs:{"name":"mengcengguanbi","class-prefix":"iconfont"},on:{"click":_vm.close}})],1),_c('section',{staticClass:"container"},[_c('van-form',{ref:"form",staticClass:"form"},[_c('van-cell-group',{staticClass:"node-box",attrs:{"inset":""}},[_c('van-field',_vm._b({staticClass:"custom-input-person",attrs:{"value":_vm.formData.deptCode,"label":"作业单位","clickable":true,"is-link":true,"required":"","rules":[
            {
              required: true,
              message: '请选择作业单位',
              trigger: 'onChange'
            }
          ]},on:{"click":_vm.deptClick},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('p',[_c('span',{staticClass:"person-box"},[_vm._v(_vm._s(_vm.formData._deptName))])])]},proxy:true}])},'van-field',_vm.fieldProps,false)),_c('van-field',{attrs:{"label":"作业内容","placeholder":"请输入作业内容","maxlength":"500","required":"","clearable":"","input-align":"right","error-message-align":"right","type":"textarea","row":"1","autosize":"","rules":[
            {
              required: true,
              message: '请填写作业内容',
              trigger: 'onChange'
            }
          ]},model:{value:(_vm.formData.content),callback:function ($$v) {_vm.$set(_vm.formData, "content", $$v)},expression:"formData.content"}}),_c('van-field',_vm._b({staticClass:"custom-input-person",attrs:{"label":"相关作业类别"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('p',[_c('span',{staticClass:"person-box"},[_vm._v(_vm._s(_vm.typeNames.toString()))])])]},proxy:true}])},'van-field',_vm.fieldProps,false))],1)],1),_c('hr'),_c('div',{staticClass:"title-bar"},[_vm._v("应急措施")]),_c('ul',{staticClass:"item-list"},_vm._l((_vm.emergencyMeasures),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(index + 1)+". "+_vm._s(item.name)+" ")])}),0),_c('hr'),_c('div',{staticClass:"title-bar"},[_vm._v("安全培训")]),_vm._l((_vm.safetyTraining),function(item,index){return _c('div',{key:index},[_c('p',{staticClass:"item-list-title"},[_vm._v(_vm._s(item.workTypeName))]),_c('ul',{staticClass:"item-list"},_vm._l((item.contentList),function(itemC,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(i + 1)+". "+_vm._s(itemC.name)+" ")])}),0)])})],2),_c('div',{staticClass:"footer"},[_c('van-button',{staticClass:"btn-default",on:{"click":_vm.close}},[_vm._v("取消")]),_c('van-button',{staticClass:"btn-primary",on:{"click":_vm.save}},[_vm._v("导出")])],1),_c('select-popup',{attrs:{"title":"作业单位","visible":_vm.deptVisible,"data":_vm.deptOptions,"popup-style":{ height: '45vh' }},on:{"close":function($event){_vm.deptVisible = false},"confirm":_vm.deptChange},model:{value:(_vm.formData.deptCode),callback:function ($$v) {_vm.$set(_vm.formData, "deptCode", $$v)},expression:"formData.deptCode"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }