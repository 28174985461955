<template>
  <div class="workticket-measure-list">
    <div v-for="group in measuresList" :key="group.id" class="measures-group">
      <div class="measures-title">{{ group.name }}</div>
      <div class="measures-table">
        <div class="measure-table">
          <div class="measure-tr first">
            <div class="measure-th">
              <van-checkbox
                v-model="group.measuresCheckedAll"
                icon-size="16"
                shape="square"
                @click="checkAll(0, group, group.measuresCheckedAll)"
                >序号</van-checkbox
              >
            </div>
            <div class="measure-th">安全措施</div>
            <div class="measure-th">确认人</div>
          </div>
          <div
            v-for="(_item, index) in group.measures"
            :key="_item.id"
            class="measure-tr"
          >
            <div class="measure-td">
              <van-checkbox
                v-model="_item.checked"
                icon-size="16"
                shape="square"
                @click="clickItem(0, group, _item)"
                >{{ index + 1 }}</van-checkbox
              >
            </div>
            <!-- <div class="measure-td">{{ _item.name }}</div> -->
            <div class="measure-td">
              <!-- <measure-input-container :text="_item.name" :value="test" /> -->
              <span v-for="(nameItem, idx) in _item.nameSplit" :key="idx">
                {{ nameItem }}
                <input
                  v-if="idx !== _item.nameSplit.length - 1"
                  v-model.trim="_item.safeMeasure.params[idx]"
                  class="measure-ipt"
                  type="text"
                  maxlength="10"
                />
              </span>
            </div>
            <div class="measure-td sign">
              <!-- <span
                v-if="_item.operatorName"
                class="operator-name"
                @click="showSignImage(_item.fileId, _item.involved)"
                ><span class="operator-name-text">{{ _item.operatorName }}</span
                ><van-icon v-if="!_item.involved" name="success"/><van-icon
                  v-else
                  name="cross"
              /></span> -->
              <Tag
                v-if="_item.operatorName"
                :type="tagTypeSwitch(_item)"
                :tick="!!_item.fileId"
                @click.native="showSignImage(_item.fileId)"
              >
                {{ _item.operatorName }}
              </Tag>
            </div>
            <div class="upload-image-box" @click="uploadPopClick(_item)">
              <div class="content">
                <van-icon name="photograph" size="16" color="#1678FF" />
                <span class="num">
                  {{ (_item.pictureFileIdList || []).length }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="group.supplementMeasures && group.supplementMeasures.length"
          class="measure-table"
        >
          <div class="measure-tr first">
            <div class="measure-th">
              <van-checkbox
                v-model="group.supplementMeasuresCheckedAll"
                icon-size="16"
                shape="square"
                @click="checkAll(1, group, group.supplementMeasuresCheckedAll)"
                >序号</van-checkbox
              >
            </div>
            <div class="measure-th">补充措施</div>
            <div class="measure-th">确认人</div>
          </div>
          <div
            v-for="(_item, index) in group.supplementMeasures"
            :key="_item.id"
            class="measure-tr"
          >
            <div class="measure-td">
              <van-checkbox
                v-model="_item.checked"
                icon-size="16"
                shape="square"
                @click="clickItem(1, group, _item)"
                >{{ index + 1 }}</van-checkbox
              >
            </div>
            <div class="measure-td">{{ _item.name }}</div>
            <div class="measure-td sign">
              <!-- <span
                v-if="_item.operatorName"
                class="operator-name"
                @click="showSignImage(_item.fileId, _item.involved)"
                ><span class="operator-name-text">{{ _item.operatorName }}</span
                ><van-icon v-if="!_item.involved" name="success"/><van-icon
                  v-else
                  name="cross"
              /></span> -->
              <Tag
                v-if="_item.operatorName"
                :type="tagTypeSwitch(_item)"
                :tick="!!_item.fileId"
                @click.native="showSignImage(_item.fileId)"
              >
                {{ _item.operatorName }}
              </Tag>
            </div>
            <div class="upload-image-box" @click="uploadPopClick(_item)">
              <div class="content">
                <van-icon name="photograph" size="16" color="#1678FF" />
                <span class="num">
                  {{ (_item.pictureFileIdList || []).length }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <select-person
      title="选择执行人(单选)"
      :visible="selectPersonVisible"
      :show-department-name="false"
      :show-phone-number="true"
      position="bottom"
      department-disabled-key="--"
      :multiple="false"
      @close="selectPersonVisible = false"
      @confirm="onPersonConfirm"
    />
    <WorkticketPersonPicker
      ref="personPicker1"
      title="选择执行人"
      @confirm="list => onPersonConfirm([], list)"
    /> -->
    <select-person-by-tabs
      :key="defaultContractor"
      title="确认人(单选)"
      :visible="SPBTPicker"
      :show-department-name="false"
      :show-phone-number="true"
      position="bottom"
      :include-children="true"
      :multiple="false"
      :is-department-auth="false"
      :default-department="selectPersonDefaultDepartment"
      :default-contractor="defaultContractor"
      :disabled-contractor-query="true"
      :show-recent-use-person="true"
      :default-tab-name="2"
      recent-type="7"
      @close="SPBTPicker = false"
      @confirm="onPersonConfirm"
    />
    <cameraUpload
      :visible="cameraUploadVb"
      position="bottom"
      mode-type="0"
      :files="rowFiles"
      @confirm="cameraUploadConfirm"
      @close="cameraUploadVb = false"
    />
  </div>
</template>

<script>
import esign from "@/components/esign";
// import SelectPerson from "@/components/SelectPerson";
// import WorkticketPersonPicker from "@/components/picker/WorkticketPersonPicker";
import {
  addStartSafetyLog,
  confirmSafetyMeasure,
  getSafetyMeasure,
  saveSafetyMeasureFile
} from "@/api/workticket";
import { fileUrl } from "@/utils/constant";
import uniq from "lodash/uniq";
import SelectPersonByTabs from "@/components/SelectPersonByTabs";
// import measureInputContainer from "./components/measureInputContainer";
import Tag from "@/components/Tag";
import cameraUpload from "./components/cameraUpload.vue";

export default {
  components: {
    // SelectPerson,
    // WorkticketPersonPicker,
    // measureInputContainer
    SelectPersonByTabs,
    Tag,
    cameraUpload
  },
  props: {
    workTypeList: {
      type: Array,
      default: () => []
    },
    specialId: {
      type: String,
      default: ""
    },
    ticketId: {
      type: String,
      default: ""
    },
    info: {
      type: Object,
      default: () => ({})
    },
    selectPersonDefaultDepartment: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      selectPersonVisible: false,
      measuresArr: [],
      measuresList: [
        {
          id: "",
          name: "",
          measures: []
        }
      ],
      groupOld: [],
      SPBTPicker: false,
      associate: true,
      cameraUploadVb: false,
      rowFiles: [],
      rowData: null
    };
  },
  computed: {
    measuresCheckeds() {
      const selected = [];
      const list = this.measuresList.filter(
        a => a.measures || a.supplementMeasures
      );
      const measures = list.map(a => {
        return [...a.measures, ...(a.supplementMeasures || [])];
      });
      measures.forEach(l => {
        l.forEach(i => {
          if (i.checked) {
            selected.push(i);
          }
        });
      });
      return selected;
    },
    defaultContractor() {
      return this.measuresCheckeds[0]
        ? this.measuresCheckeds[0].contractorId || "999999999999"
        : "999999999999";
    },
    disabledContractorQuery() {
      return this.defaultContractor ? true : false;
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.getSafetyMeasure();
    this.addStartSafetyLog();
  },
  beforeDestroy() {
    window.sessionStorage.removeItem("pictureData");
  },
  methods: {
    getPictureData() {
      return JSON.parse(window.sessionStorage.getItem("pictureData"));
    },
    // getFileUrl(id, downloadFlag = true) {
    //   return `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=${downloadFlag}&fileName=${id}`;
    // },
    cameraUploadConfirm(fileList) {
      const data = JSON.parse(window.sessionStorage.getItem("pictureData"));
      const ids = fileList.map(i => i.id);
      const find = data.find(i => i.safetyMeasureId === this.rowData.id);
      find.pictureFileIdList = [...ids];
      this.rowData.pictureFileIdList = [...ids];
      window.sessionStorage.setItem("pictureData", JSON.stringify(data));
      this.rowData = null;
      this.cameraUploadVb = false;
    },
    uploadPopClick(rowData) {
      this.rowData = rowData;
      this.rowFiles = (rowData.pictureFileIdList || []).map(i => ({
        // url: this.getFileUrl(i),
        id: i,
        name: `${i}.jpg`
      }));
      this.cameraUploadVb = true;
    },
    clickItem(type, group, item) {
      const groupMeasuresNameObj = {
        0: "measures",
        1: "supplementMeasures"
      };
      const measuresCheckedAllObj = {
        0: "measuresCheckedAll",
        1: "supplementMeasuresCheckedAll"
      };
      const list = group[groupMeasuresNameObj[type]];
      if (type === 0) this.groupOld = list;
      const checkedList = list.filter(a => a.checked);
      if (checkedList.length === list.length) {
        group[measuresCheckedAllObj[type]] = true;
      } else {
        group[measuresCheckedAllObj[type]] = false;
      }
    },
    checkAll(type, group, checked) {
      const groupMeasuresNameObj = {
        0: "measures",
        1: "supplementMeasures"
      };
      group[groupMeasuresNameObj[type]].forEach(item => {
        item.checked = checked;
      });
    },
    getSafetyMeasure(cover) {
      const ps = [];
      const varTag = "【】";
      // const inputTemplate = `<component v-bind:is="measureInput" :value="" ></component>`;
      this.workTypeList.forEach(item => {
        ps.push({
          name: item.name,
          contractorId: item.contractorStatus == 1 ? item.deptCode : "", // 如果是承包商, 则选择承包商人员id, item.contractorStatus = 1 说明deptCode是承包商id
          // enterpriseId: item.contractorStatus == 0 ? item.deptCode : "",
          id: item.id,
          p: getSafetyMeasure(this.ticketId, item.specialId)
        });
      });
      let pData = [];
      const pictureData = [];
      if (cover) {
        pData = JSON.parse(window.sessionStorage.getItem("pictureData"));
      }
      Promise.all(ps.map(a => a.p)).then(res => {
        const l = [];
        const grounpLen = this.groupOld.length;
        // NOTE: 暂时用groupOld数据恢复编辑的数据，应对签名后刷新数据输入框内容消失。后面可以改为签名后直接赋值数据
        // safeMeasure下有三个属性，template为【】不带值的文本，content为【】带值的文本，params为数组
        res.forEach((list, index) => {
          const curType = this.workTypeList[index];
          list.forEach((_item, idx) => {
            if (!_item.safeMeasure) _item.safeMeasure = {};
            const nameSplit = (_item.safeMeasure.template || "").split(varTag);
            _item.checked = false;
            _item.nameSplit = nameSplit;
            if (grounpLen) {
              const old = this.groupOld.find(old => old.id === _item.id);
              _item.safeMeasure.params = old?.safeMeasure?.params || [];
            } else {
              _item.safeMeasure.params =
                _item.safeMeasure.params ||
                Array(nameSplit.length - 1).fill("");
            }
            if (cover) {
              const curData = pData.find(i => i.safetyMeasureId === _item.id);
              _item.pictureFileIdList = curData.pictureFileIdList;
            }
            pictureData.push({
              specialTicketId: curType.specialId,
              safetyMeasureId: _item.id,
              pictureFileIdList: _item.pictureFileIdList || []
            });
          });
          this.measuresArr = this.measuresArr.concat(list);
          const obj = {
            name: ps[index].name,
            id: ps[index].id,
            measuresCheckedAll: false,
            measures: list.filter(a => {
              a.contractorId = ps[index].contractorId;
              // a.enterpriseId = ps[index].enterpriseId;
              return [0, 2].includes(a.type);
            }),
            supplementMeasuresCheckedAll: false,
            supplementMeasures: list.filter(a => {
              a.contractorId = ps[index].contractorId;
              // a.enterpriseId = ps[index].enterpriseId;
              return a.type === 1;
            })
          };
          l.push(obj);
        });
        window.sessionStorage.setItem(
          "pictureData",
          JSON.stringify(pictureData)
        );
        this.measuresList = l;
      });
    },
    comGetSafetyMeasureData() {
      return this.measuresList;
    },
    showPersonPopup(v) {
      this.selectPersonVisible = v;
    },
    showSignImage(fileId, involved) {
      esign.showImage(fileUrl + fileId);
    },
    onPersonConfirm(ids, list) {
      const person = list[0];
      // 如果没有选择人员则直接返回
      if (!person) {
        return;
      }
      // 跳转到签名页面
      esign.show(imgRes => {
        const { id } = imgRes;
        // id
        // name
        // size
        // uploadTime
        // 根据选择的人员和签名发送请求保存,保存成功后返回到列表页
        confirmSafetyMeasure({
          ticketId: this.ticketId,
          fileId: id,
          operator: person.id || person.value || person.personId,
          operatorType: person._mechanismType, // 人员机构归属，0企业，1承包商
          safetyMeasureIdList: this.measuresCheckeds.map(a => {
            if ([0, 2].includes(a.type)) {
              const list = a.safeMeasure.params;
              let content = "";
              const nameSplit = a.nameSplit || [];
              const len = nameSplit.length;
              nameSplit.forEach((item, idx) => {
                content += item;
                if (len - 1 !== idx) {
                  content += `【${list[idx] || ""}】`;
                }
              });
              return {
                ...a.safeMeasure,
                content,
                involved: this.associate ? 0 : 1
              };
            }
            return {
              id: a.id,
              params: [],
              content: a.name,
              template: a.name,
              involved: this.associate ? 0 : 1
            };
          })
        }).then(res => {
          if (!res) {
            this.$toast("确认失败, 请重试");
            return;
          }
          this.getSafetyMeasure(true);
        });
      });
    },
    // sign(isContractor) {
    //   // 判断是否选择措施了
    //   if (this.measuresCheckeds.length === 0) {
    //     this.$toast("请选择需要签字的选项");
    //     return;
    //   }
    //   // 如果是承包商, 则承包商签字
    //   if (isContractor) {
    //     const contractorIds = this.measuresCheckeds.map(a => a.contractorId);
    //     if (uniq(contractorIds).length > 1) {
    //       this.$dialog.alert({
    //         message: "已选安全措施承包商不同，请重新选择"
    //       });
    //       return;
    //     }
    //     // 显示选择承包商人员页面
    //     this.$refs.personPicker1.showAllContractor(contractorIds[0]);
    //     return;
    //   }
    //   // 跳转到选择人员的页面
    //   this.showPersonPopup(true);
    // },
    sign(associate) {
      // 判断是否选择措施了
      if (this.measuresCheckeds.length === 0) {
        this.$toast("请选择需要签字的选项");
        return;
      }
      const contractorIds = this.measuresCheckeds.map(a => a.contractorId);
      if (uniq(contractorIds).length > 1) {
        this.$dialog.alert({
          message: "已选安全措施承包商不同，请重新选择"
        });
        return;
      }
      this.associate = associate;
      this.SPBTPicker = true;
    },
    // 添加开始安全交底日志
    addStartSafetyLog() {
      addStartSafetyLog(this.ticketId);
    },
    tagTypeSwitch(item) {
      if (item.fileId) {
        return item.involved ? "purple" : "green";
      }
      return "white";
    }
  }
};
</script>

<style lang="scss" scoped>
.workticket-measure-list {
  overflow-x: hidden;
  .measures-group {
    .measures-title {
      height: 48px;
      line-height: 48px;
      font-size: 22px;
      padding: 0 16px;
      font-weight: 700;
      overflow-x: auto;
      // overflow: hidden;
      // text-overflow: ellipsis;
      white-space: nowrap;
    }
    .measures-table {
      width: 100%;
      .measure-tr {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
        padding: 0 16px;
        box-sizing: border-box;
        &:not(.first) {
          min-height: 54px;
        }
        &:not(:last-child) {
          &::after {
            position: absolute;
            content: "";
            bottom: 0;
            left: 16px;
            right: 16px;
            height: 1px;
            background-color: #eceef2;
            transform: scaleY(0.5);
          }
        }
        &.first {
          height: 32px;
          background-color: #eceef2;
        }
        .measure-th,
        .measure-td {
          color: #8c8f97;
          font-size: 14px;
          .van-checkbox {
            ::v-deep {
              .van-icon {
                background-color: #fff;
              }
              .van-checkbox__icon--checked {
                .van-icon {
                  background-color: $--color-primary;
                }
              }
              .van-checkbox__label {
                color: #8c8f97;
              }
            }
          }
          &:nth-child(1) {
            width: 80px;
          }
          &:nth-child(2) {
            flex: 1;
            word-break: break-all;
            padding: 2px 8px 2px 0;
          }
          &:last-child {
            width: 110px;
          }
        }
        .measure-td.sign {
          width: 110px;
        }
        .measure-td {
          color: #2e2e4d;
          line-height: 24px;
          .operator-name {
            display: flex;
            align-items: center;
            width: 90px;
            border-radius: 16px;
            color: #64ae08;
            border: 1px solid #75c70e;
            background-color: #dcffdb;
            padding: 0 10px;
            box-sizing: border-box;
            white-space: nowrap;
            .operator-name-text {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              font-size: 14px;
              text-align: center;
              flex: 1;
            }
            .van-icon-success {
              line-height: 32px;
              margin-left: 6px;
              font-size: 18px;
              vertical-align: top;
            }
          }
          .van-checkbox {
            ::v-deep {
              .van-checkbox__icon--checked {
                .van-icon {
                  background-color: $--color-primary;
                }
              }
              .van-checkbox__label {
                color: #2e2e4d;
              }
            }
          }
          .measure-ipt {
            width: 30px;
            height: 14px;
            font-size: 14px;
            border: 1px solid #ccc;
            border-radius: 2px;
          }
        }
        .upload-image-box {
          position: absolute;
          width: 60px;
          height: 30px;
          color: #1678ff;
          font-size: 12px;
          background-color: #eceef2;
          border-radius: 0 0 30px 30px;
          top: 0;
          right: -30px;
          .content {
            display: flex;
            flex-direction: column;
            margin: 2px 0 0 12px;
            .num {
              margin: 0 0 0 5px;
            }
          }
        }
      }
    }
  }
}
</style>
