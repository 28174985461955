<template>
  <van-popup
    v-model="visible"
    class="custom-popup"
    :close-on-click-overlay="false"
    :overlay="false"
    get-container="body"
    v-bind="$attrs"
    @opened="onOpen"
    @close="onClose"
    @click-overlay="onClose"
    v-on="$listeners"
  >
    <section class="header">
      <!-- <van-icon
        class="close-icon"
        name="mengcengguanbi"
        class-prefix="iconfont"
        @click.native="iconClose()"
      /> -->
      <van-icon
        name="arrow-left close-icon"
        size="22"
        @click.native="iconClose()"
      />
      <span class="title">{{ title }}</span>
      <span></span>
    </section>
    <section class="content">
      <template v-if="modeType === '0'">
        <p class="sub-title">交底内容</p>
        <div class="default-text">
          <van-checkbox-group v-model="checks">
            <van-checkbox
              v-for="item of checksData"
              :key="item"
              :name="item"
              shape="square"
              icon-size="16px"
            >
              {{ item }}
            </van-checkbox>
          </van-checkbox-group>
        </div>
        <div v-for="(item, k) of list" :key="k" class="input-box">
          <van-icon
            class="text-del-icon"
            name="shanchu"
            class-prefix="iconfont"
            @click.native="delteText(k)"
          />
          <van-field
            v-model.trim="item.text"
            class="input"
            placeholder="请输入"
            input-align="left"
            clearable
            maxlength="500"
            show-word-limit
            v-bind="$attrs"
            v-on="$listeners"
          />
        </div>
        <p class="add-box">
          <span @click="addText">
            <van-icon
              class="add-icon"
              name="tianjiagongzuobuzhou"
              class-prefix="iconfont"
              size="16px"
            />
            补充其他内容
          </span>
        </p>
      </template>
      <template v-if="['1', '2'].includes(modeType)">
        <p class="sub-title">交底内容</p>
        <p v-for="(item, k) of formData.contentList" :key="k" class="label">
          {{ `${k + 1}、${item}` }}
        </p>
      </template>
      <template v-if="modeType === '2'">
        <p class="bd">
          <span>{{ formData.ctime }} </span>
          <span>{{ formData.cuserName }}</span>
        </p>
      </template>
    </section>
    <section class="footer">
      <template v-if="['0', '1'].includes(modeType)">
        <div v-if="modeType === '1'" class="read-confirm">
          <van-checkbox v-model="read" icon-size="16px"
            >阅读并承诺悉知<span style="color: #1678FF;">安全交底清单</span
            >内容</van-checkbox
          >
        </div>
        <div class="btn-box">
          <van-button type="default" class="dispersion-btn" @click="onClose"
            >取消
          </van-button>
          <van-button type="info" class="dispersion-btn" @click="onConfirm"
            >{{ modeType === "0" ? "保存" : "确认" }}
          </van-button>
        </div>
      </template>
    </section>
  </van-popup>
</template>

<script>
import {
  saveSafetyRegulation,
  getSafetyRegulation,
  getSafetyList
} from "@/api/workticket";

export default {
  name: "SafeInventory",
  components: {},
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: "安全交底清单"
    },
    // 作业大票id
    ticketId: {
      type: String
    },
    // 模式 0编辑、1确认、2查看
    modeType: {
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      read: false,
      checksData: [
        // "作业现场和作业过程中可能存在的危险、有害因素及采取的具体安全措施与应急措施；",
        // "会同作业单位组织作业人员到作业现场，了解和熟悉现场环境，进一步核实安全措施的可靠性，熟悉应急救援器材的位置及分布；",
        // "涉及断路、动土作业时，应对作业现场的地下隐蔽工程进行交底。"
      ],
      checks: [],
      list: [
        {
          text: ""
        }
      ],
      formData: {
        ticketId: "",
        contentList: [],
        cuserName: "",
        ctime: ""
      }
    };
  },
  computed: {},
  watch: {
    visible: {
      handler(newVal) {
        if (newVal) {
        }
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    async getPageData() {
      const safetyList = await getSafetyList({
        orgCode: this.$store.state.login.userInfo.orgCode
      });
      this.checksData = safetyList.map(item => item.name);
      const res = await getSafetyRegulation({
        ticketId: this.ticketId
      });
      if (this.modeType === "0") {
        const cl = res?.contentList || [];
        const checkSet = [];
        this.checksData.forEach(i => {
          const idx = cl.findIndex(f => f === i);
          if (idx !== -1) {
            checkSet.push(i);
            cl.splice(idx, 1);
          }
        });
        this.checks = checkSet;
        const other = cl.map(i => ({ text: i }));
        this.list = other.length ? other : [{ text: "" }];
      } else {
        this.formData = res;
      }
    },
    addText() {
      this.list.push({ text: "" });
    },
    delteText(idx) {
      this.list.splice(idx, 1);
    },
    onOpen() {
      this.read = false;
      this.getPageData();
      this.$emit("opened");
    },
    onClose() {
      this.$emit("close");
    },
    async onConfirm() {
      if (this.modeType === "0") {
        const cl = this.checks.concat(
          this.list.filter(i => i.text).map(i => i.text)
        );
        if (!cl.some(i => i)) {
          return this.$toast("请填写安全交底清单");
        }
        const params = {
          ticketId: this.ticketId,
          contentList: cl
        };
        try {
          await saveSafetyRegulation(params);
          this.$emit("confirm");
          this.$emit("close");
        } catch (e) {
          this.$toast("保存失败");
        }
      } else if (this.modeType === "1") {
        if (!this.read) {
          return this.$toast("请勾选【阅读并承诺悉知】");
        }
        this.$emit("confirm");
        this.$emit("close");
      }
      // this.$emit("close");
    },
    iconClose() {
      // this.$dialog
      //   .confirm({
      //     message: "未保存的更改，确定要退出吗？",
      //     className: "custom-confirm-dialog",
      //     confirmButtonText: "放弃",
      //     confirmButtonColor: "#F13030",
      //     cancelButtonText: "再看看",
      //     cancelButtonColor: "#6E6E7D"
      //   })
      //   .then(() => {
      //     this.$emit("close");
      //   });
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.custom-confirm-dialog {
  .van-dialog__message {
    font-size: 16px;
    font-weight: 500;
    color: #242f3b;
    line-height: 22px;
  }
  .van-dialog__footer {
    border-top: 1px solid #eaecf1;
  }
  .van-dialog__cancel {
    border-right: 1px solid #eaecf1;
  }
}
</style>

<style lang="scss" scoped>
.custom-popup {
  height: 100vh;
  overflow: hidden;
  .header {
    // position: relative;
    display: flex;
    justify-content: space-between;
    padding: 14px 16px 13px;
    // padding-bottom: 20px;
    // padding-top: 30px;
    .close-icon {
      // position: absolute;
      // top: 16px;
      // left: 0;
      color: #9496a3;
      font-size: 16px;
    }
    .title {
      font-size: 18px;
      font-weight: 400;
      color: #2e2e4d;
      line-height: 17px;
    }
  }
  .content {
    padding: 16px;
    height: calc(100vh - 160px);
    overflow-y: auto;
    .sub-title {
      font-size: 16px;
      font-weight: 500;
      color: #15171b;
      margin: 0 0 16px;
    }
    .label {
      font-size: 14px;
      font-weight: 400;
      color: #15171b;
      line-height: 20px;
      padding: 0 0 8px;
    }
    .bd {
      font-size: 12px;
      font-weight: 400;
      color: #a0a2a6;
      line-height: 12px;
      text-align: right;
    }
    .default-text {
      .van-checkbox {
        align-items: initial;
        padding: 0 0 12px;
        ::v-deep {
          // .van-checkbox__icon {
          //   width: 16px;
          //   height: 16px;
          //   border-radius: 2px;
          //   border: 1px solid #D4DBE3;
          // }
          .van-checkbox__label {
            font-size: 14px;
            font-weight: 400;
            color: #15171b;
            line-height: 20px;
          }
        }
      }
    }
    .add-box {
      font-size: 12px;
      font-weight: 400;
      color: #2e2e4d;
      line-height: 17px;
      vertical-align: middle;
    }
    .add-icon {
      color: #1676ff;
      vertical-align: middle;
    }
    .input-box {
      margin: 0 0 16px;
      position: relative;
      .text-del-icon {
        color: #f75858;
        position: absolute;
        right: -7px;
        top: -6px;
        z-index: 2;
      }
      .input {
        padding: 8px 10px;
        border: 1px solid #e1e3e8;
        font-size: 14px;
        font-weight: 400;
        color: #2e2e4d;
        line-height: 20px;
        min-height: 22px;
        ::v-deep .van-field__word-limit {
          font-size: 10px;
          color: #cfd2d9;
          padding: 0 16px;
        }
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    // height: 50px;
    width: 100vw;
    box-sizing: border-box;
    padding: 0 16px 16px;
    line-height: 50px;
    background-color: #fff;
    z-index: 2;
    .read-confirm {
      margin: 0 0 16px;
      font-size: 14px;
      .van-checkbox {
        align-items: center;
        ::v-deep .van-checkbox__label {
          font-size: 12px;
          color: #a0a2a6;
          line-height: 20px;
        }
      }
    }
    .btn-box {
      display: flex;
      justify-content: space-around;
    }
    .dispersion-btn {
      // width: 120px;
      flex: 1;
    }
    .dispersion-btn:not(:last-child) {
      margin: 0 13px 0 0;
    }
    .van-button {
      height: 40px;
      line-height: 40px;
      border-radius: $--button-height;
    }
    .van-button--normal {
      padding: 0 24px;
    }
  }
}
</style>
