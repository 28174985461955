<template>
  <van-popup
    v-model="visible"
    :close-on-click-overlay="false"
    :overlay="false"
    get-container="body"
    v-bind="$attrs"
    class="select-person select-camera"
    @opened="onOpen"
    @close="onClose"
    @click-overlay="onClose"
    v-on="$listeners"
  >
    <section class="select-person__container">
      <div class="select-person__header">
        <h3 class="select-person__title">{{ title }}</h3>
        <van-icon
          class="close-icon"
          name="mengcengguanbi"
          class-prefix="iconfont"
          @click.native="$emit('close')"
        />
      </div>
      <van-tabs v-model="activeName" @click="onSearch">
        <van-tab title="摄像头" name="0" />
        <van-tab title="移动摄像头" name="1" />
        <div class="select-person__search">
          <van-field
            v-model="queryInfo.departmentName"
            left-icon="shouqi"
            readonly
            icon-prefix="iconfont"
            placeholder="所属部门"
            class="select-person__department"
            @click="departmentVisible = true"
          />
          <van-search
            v-model="queryInfo.videoName"
            placeholder="请输入关键词"
            @search="onSearch"
            @input="onSearch"
          >
            <template #left-icon>
              <van-icon
                name="sousuo"
                class-prefix="iconfont"
                class="search-icon"
              />
            </template>
          </van-search>
        </div>
        <div class="select-person__list">
          <van-pull-refresh
            v-model="refreshing"
            class="select-person__list-wrapper--choose"
            :class="{
              'select-person__list-wrapper': true
            }"
            @refresh="onSearch"
          >
            <van-checkbox-group v-model="chooseIds" :border="false">
              <van-list
                v-model="loading"
                :error.sync="isError"
                :finished="finished"
                :finished-text="isError ? '' : '没有更多了'"
                error-text="加载失败，请稍后再试！"
              >
                <van-cell
                  v-for="(item, index) in list"
                  :key="index"
                  :border="false"
                  clickable
                  @click="selectCamera(item)"
                >
                  <van-checkbox :name="item.value" label-position="left">
                    <template #icon>
                      <van-icon
                        :name="
                          item.checked ? 'danxuan-yixuan' : 'danxuan-weixuan'
                        "
                        class-prefix="iconfont"
                        :color="item.checked ? ' #1676FF' : '#D4DBE3'"
                        class="choose-icon"
                      />
                    </template>
                    <div class="checkbox-list">
                      <span class="custom-title">{{ item.label }}</span>
                      <span class="additional"
                        >（{{ item.departmentName || "" }}）</span
                      >
                    </div>
                  </van-checkbox>
                </van-cell>
              </van-list>
            </van-checkbox-group>
          </van-pull-refresh>
        </div>
        <div class="footer">
          <van-button block round type="info" @click="onConfirm"
            >确定</van-button
          >
        </div>
      </van-tabs>
    </section>
    <cascade-department
      ref="cascadeDepartment"
      v-model="departmentCur"
      :include-children="includeChildren"
      :is-auth="true"
      :visible="departmentVisible"
      @close="departmentVisible = false"
      @confirm="onTreeConfirm"
    />
  </van-popup>
</template>

<script>
import { getPageCamera, getPageMobileCamera } from "@/api/workticket";
import uniqBy from "lodash/uniqBy";

export default {
  name: "SelectCameraPopup",
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: "摄像头"
    },
    includeChildren: {
      type: Boolean,
      default: false
    },
    videoId: [String, Number],
    videoType: [String, Number],
    videoName: String,
    poiId: [String, Number]
  },
  data() {
    return {
      loading: false,
      refreshing: false,
      finished: false,
      isError: false,
      departmentCur: "",
      departmentVisible: false,
      activeName: "0",
      chooseIds: [],
      chooseList: [],
      list: [],
      total: 0,
      queryInfo: {
        videoName: "",
        poiId: "",
        departmentList: [],
        page: 1,
        size: 10,
        subDepartment: this.includeChildren
      }
    };
  },
  methods: {
    onOpen() {
      this.activeName =
        this.videoType != null && this.videoType !== ""
          ? String(this.videoType)
          : "0";
      this.chooseIds = [];
      this.chooseList = [];
      if (this.videoId) {
        this.chooseIds.push(this.videoId);
        this.chooseList.push({
          label: this.videoName,
          value: this.videoId,
          activeName: this.activeName
        });
      }
      this.queryInfo.poiId = this.poiId;
      this.onSearch();
    },
    onClose() {
      this.queryInfo.page = 1;
      this.$emit("close");
    },
    onConfirm() {
      this.$emit("confirm", this.chooseList[0] ?? null);
      this.$emit("close");
    },
    onTreeConfirm(val, { include, children, childrenIds }) {
      this.departmentCur = val?.id || "";
      this.queryInfo.department = val?.id || "";
      this.queryInfo.subDepartment = includeInfo?.include;
      this.queryInfo.departmentName = val.label;
      this.onSearch();
    },
    onSearch() {
      this.queryInfo.page = 1;
      this.list = [];
      this.getList();
    },
    async getList() {
      this.finished = false;
      this.isError = false;
      this.loading = true;
      if (this.refreshing) {
        this.list = [];
        this.refreshing = false;
      }
      try {
        let list, total;
        if (this.activeName === "0") {
          const res = await getPageCamera(this.queryInfo);
          list = res.list ?? [];
          total = res.total ?? 0;
        } else {
          const res = await getPageMobileCamera(this.queryInfo);
          list = res.list ?? [];
          total = res.total ?? 0;
        }
        list = list.map(item => {
          item.label = item.videoName;
          item.value = item.id;
          item.checked = false;
          return item;
        });
        this.loading = false;
        this.total = total;
        // 当前列表数量等于总数 暂无内容
        if (list && list.length) {
          this.list =
            this.queryInfo.page === 1
              ? list
              : uniqBy(this.list.concat(list), "value");
          // 回显之前设置的状态
          this.onRender(this.chooseList[0]);
          if (list.length < this.queryInfo.size) {
            this.finished = true;
          } else {
            // 加载成功下次页码加1
            this.queryInfo.page++;
          }
        } else {
          this.finished = true;
        }
      } catch (e) {
        this.loading = false;
        this.finished = true;
        this.isError = true;
      }
    },
    onRender(x) {
      if (!x) return;
      this.list.forEach(y => {
        if (y.id === x.value) y.checked = true;
      });
    },
    selectCamera(item) {
      const obj = this.list.find(a => a.checked && a.value !== item.value);
      if (obj) obj.checked = false;
      item.checked = !item.checked;
      item.activeName = this.activeName;
      if (item.checked) {
        this.chooseList = [item];
      } else {
        const index = this.chooseList.findIndex(a => a.value === item.value);
        if (index === -1) return;
        this.chooseList.splice(index, 1);
      }
      this.chooseIds = this.chooseList.map(a => a.value);
    }
  }
};
</script>

<style lang="scss">
.select-camera {
  .van-tabs__wrap {
    border-bottom: 1px solid #c7c9d0;
    margin-left: -16px;
    margin-right: -16px;
  }
  .van-tabs__content {
    padding-top: 16px;
  }
  .footer {
    position: fixed;
    width: calc(100% - 32px);
    bottom: 0;
    padding-bottom: 26px;
    background-color: #fff;
  }
}
</style>
